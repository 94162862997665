import React from "react";
import { Globe2, Server, Network, Gauge, CheckCircle2, Shield } from "lucide-react";
import SVG from "../../../assets/hosting/datacenter.svg";

export default function Datacenters() {
  const datacenters = [
    {
      image: 'https://images.ctfassets.net/uhwn5kvabnul/1UJ8ud70w9ViOJ90lIEtal/c6915573c66903a043d53e10484dccb7/location-EU_EN.png?w=240&fit=pad&q=80&fm=webp',
      region: 'Europa',
      description: 'Servimos a Europa, África y Medio Oriente desde 5 centros de datos en Alemania.',
      features: [
        'Latencia < 20ms',
        '5 centros de datos',
        'Certificación Tier III',
        'Redundancia N+1'
      ]
    },
    {
      image: 'https://images.ctfassets.net/uhwn5kvabnul/6PK4klnJBERK51DwIImYU1/26593abc03f29746a9ea6c98678dd9a5/location-US.png?w=240&fit=pad&q=80&fm=webp',
      region: 'Estados Unidos',
      description: 'Tres regiones disponibles: Este (Nueva York), Oeste (Seattle) y Central (St. Louis).',
      features: [
        'Latencia < 30ms',
        '3 centros de datos',
        'Certificación Tier III',
        'Redundancia N+2'
      ]
    },
    {
      image: 'https://images.ctfassets.net/uhwn5kvabnul/2XDGW4obbgTgqREYhRboNL/51552ed3791364c317bc591946d18fe3/location-UK.png?w=240&fit=pad&q=80&fm=webp',
      region: 'Reino Unido',
      description: 'Centro de datos en Portsmouth para servir al Reino Unido e Irlanda.',
      features: [
        'Latencia < 25ms',
        '1 centro de datos',
        'Certificación Tier III',
        'Redundancia N+1'
      ]
    },
    {
      image: 'https://images.ctfassets.net/uhwn5kvabnul/50rkXGhkCS8zq6JUtOKRHU/525fe33b861d7173fb9f0d9b7ad028d5/location-asia.png?w=240&fit=pad&q=80&fm=webp',
      region: 'Singapur',
      description: 'Optimizado para el sur y sudeste de Asia desde nuestro centro en Singapur.',
      features: [
        'Latencia < 40ms',
        '1 centro de datos',
        'Certificación Tier III',
        'Redundancia N+1'
      ]
    },
    {
      image: 'https://images.ctfassets.net/uhwn5kvabnul/7mOsuTTRQ84NOl9QJYdJ8c/5edb7bba59e55c7bda6bcad6c8696fb8/location-jp.png?w=240&fit=pad&q=80&fm=webp',
      region: 'Japón',
      description: 'Centro de datos en Tokio para Japón, Corea y el este de Asia.',
      features: [
        'Latencia < 35ms',
        '1 centro de datos',
        'Certificación Tier III',
        'Redundancia N+1'
      ]
    },
    {
      image: 'https://images.ctfassets.net/uhwn5kvabnul/TcWstXwmZbhcH7EMI0D6M/11d1b3a2fa0965f45afac6a3ebfb2ff4/location-australia.png?w=240&fit=pad&q=80&fm=webp',
      region: 'Australia',
      description: 'Conectividad premium para Australia desde nuestro centro en Sydney.',
      features: [
        'Latencia < 45ms',
        '1 centro de datos',
        'Certificación Tier III',
        'Redundancia N+1'
      ]
    }
  ];

  return (
    <section className="relative">
      {/* Mapa y Estadísticas */}
      <div className="relative bg-black">
        {/* Patrón de fondo */}
        <div className="absolute inset-0 opacity-20"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          }}
        />

        <div className="max-w-[90rem] mx-auto px-4 py-20">
          {/* Encabezado */}
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-white mb-6">
              Red Global de Alto Rendimiento
            </h2>
            <div className="flex justify-center gap-8 flex-wrap">
              {[
                { number: '11', label: 'Data Centers' },
                { number: '8', label: 'Regiones Globales' },
                { number: '99.99%', label: 'Uptime Garantizado' },
                { number: '< 50ms', label: 'Latencia Global' }
              ].map((stat, index) => (
                <div key={index} className="text-center">
                  <div className="text-3xl font-bold text-blue-500 mb-2">{stat.number}</div>
                  <div className="text-sm text-gray-400 uppercase tracking-wider">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Mapa Interactivo */}
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-b from-black/50 to-transparent pointer-events-none" />
            <img 
              src={SVG} 
              alt="Global Data Centers Map" 
              className="w-full h-auto max-w-6xl mx-auto"
            />
          </div>
        </div>
      </div>

      {/* Grid de Locaciones */}
      <div className="bg-gradient-to-b from-black to-gray-900 pt-20 pb-32">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {datacenters.map((dc, index) => (
              <div 
                key={index} 
                className="bg-white/5 backdrop-blur-lg rounded-2xl p-8 border border-white/10 hover:border-blue-500/50 transition-all duration-300"
              >
                <div className="flex items-start gap-6">
                  <img 
                    src={dc.image} 
                    alt={dc.region} 
                    className="w-12 h-12 object-contain"
                  />
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">
                      {dc.region}
                    </h3>
                    <p className="text-gray-400 text-sm mb-6">
                      {dc.description}
                    </p>
                    <ul className="space-y-3">
                      {dc.features.map((feature, idx) => (
                        <li key={idx} className="flex items-center gap-2 text-sm text-gray-300">
                          <CheckCircle2 className="w-4 h-4 text-blue-500" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Próximas Ubicaciones */}
          <div className="mt-24 text-center">
            <div className="inline-block px-6 py-3 rounded-full bg-blue-500/10 border border-blue-500/20 mb-8">
              <span className="text-blue-400 text-sm font-medium">Próximamente</span>
            </div>
            <h3 className="text-3xl font-bold text-white mb-4">
              Expandiendo Nuestra Red Global
            </h3>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Continuamos creciendo para servirte mejor. Nuevos centros de datos próximamente en{' '}
              <span className="text-white font-semibold">Costa Rica</span> e{' '}
              <span className="text-white font-semibold">India</span>.
            </p>
          </div>
        </div>
      </div>

      {/* Características Técnicas */}
      <div className="bg-gray-900 py-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {[
              {
                icon: Server,
                title: 'Hardware Enterprise',
                description: 'Servidores Dell y HP de última generación con redundancia N+1'
              },
              {
                icon: Network,
                title: 'Conectividad Premium',
                description: 'Múltiples proveedores Tier 1 con redundancia automática'
              },
              {
                icon: Shield,
                title: 'Seguridad Avanzada',
                description: 'Protección DDoS, firewalls dedicados y monitoreo 24/7'
              }
            ].map((feature, index) => (
              <div key={index} className="text-center">
                <div className="w-16 h-16 bg-blue-500/10 rounded-2xl flex items-center justify-center mx-auto mb-6">
                  <feature.icon className="w-8 h-8 text-blue-400" />
                </div>
                <h4 className="text-xl font-semibold text-white mb-4">{feature.title}</h4>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
