const en = {
  translation: {
    // Navbar
    // Navbar
    Navbar: {
      home: "Home",
      services: "Services",
      development: "Development",
      web: "Web Development",
      apps: "App Development",
      store: "Online Stores | E-Commerce",
      software: "Custom Software",
      softwareBusiness: "Business Software",
      apis: "API Development",
      anothers: "Other Services",
      consulting: "Consulting",
      seo: "SEO",
      support: "Maintenance and Technical Support",
      ia: "Artificial Intelligence",
      chatbots: "Chatbot Development",
      gpts: "Custom GPTs",
      news: "With OpenAIs GPTs, we can develop Chatbots and Custom Assistants to meet the specific needs of your company or business. These systems are designed to provide quick and accurate responses to both you and your customers, delivering agile and effective service that boosts customer satisfaction and business performance.",
      hosting: "Hosting",
      clients: "Clients",
      about: "About Us",
      contact: "Contact",
    },

    NotFoundTitle: {
      text: "Page not found",
    },
    NotFoundDescription: {
      text: "We searched high and low, but couldn't find what you're looking for.Let's find a better place for you to go.",
    },
    NotFoundButton1: {
      text: "Go back",
    },
    NotFoundButton2: {
      text: "Contact Us",
    },
    Projects: {
      text: "Projects",
    },
    ViewProjectsDescription: {
      text: "We have a lot of projects that you can see and learn from them.",
    },
    View: {
      text: "View",
    },
    OurBlog: {
      text: "Our Blog",
    },
    ViewOurBlog: {
      text: "In our blog, you can find a lot of information about the latest news of technology.",
    },
    ReadNewsBlog: {
      text: "Read blog",
    },
    ChatToUs: {
      text: "Chat to us",
    },
    ChatDescription: {
      text: "We are here to help you. Chat with us if you have any questions.",
    },
    ChatButton: {
      text: "Chat with our team",
    },
    HeroTitle: {
      text: "To the Stars Together!",
    },
    Explore: {
      text: "Explore",
    },
    PressBtn: {
      text: "Press the button",
    },
    ParallaxTitle: {
      text: "We take your business to the clouds",
    },
    ParallaxTitle2: {
      text: "We develop your idea and take it to reality",
    },
    ParallaxTitle3: {
      text: "Web, Apps, Software, Stores, AI and more",
    },
    FeaturesTitle: {
      text: "We help you <br/> take your business <br/> to the clouds",
    },
    Feature1: {
      title: "Websites",
      description:
        "We create websites tailored to your needs, using the latest technologies and with a modern and attractive design.",
    },
    Feature2: {
      title: "Mobile Apps",
      description:
        "We develop native mobile applications for Android and iOS with the latest technologies and a modern and attractive design.",
    },
    Feature3: {
      title: "Custom Software",
      description:
        "With custom software tailored to your needs, you can optimize your processes and increase your business productivity.",
    },
    Feature4: {
      title: "Online Stores (E-commerce)",
      description:
        "Digitize your business and sell your products online with a virtual store tailored to your needs.",
    },
    Feature5: {
      title: "Web Hosting",
      description:
        "We offer secure and reliable web hosting for your website, with quality technical support.",
    },
    Feature6: {
      title: "Artificial Intelligence (AI)",
      description:
        "We develop Artificial Intelligence (AI) solutions to automate processes and increase your business productivity.",
    },

    CTATitle: {
      text: "Our Clients Recommend Us",
    },
    CTASubtitle: {
      text: "Each client is unique and special to us, that's why we strive to give our best so that their experience is the best.",
    },
    CTAClientTestimonial: {
      text: "On several occasions, I have had the fortune to hire WeCode's services, and each time they have excelled. The team of developers is very capable and provides excellent customer service. I would recommend them 100%.",
    },
    CTAStat1: {
      text: "In sales in just <strong>48 hours</strong> with our Ecommerce system and payment gateway with a single client.",
    },
    CTAStat2: {
      text: "Of our clients have experienced an increase in their online sales and strengthen their brand.",
    },
    CTAStat3: {
      text: "Of our clients are satisfied with our services and recommend us.",
    },
    CTAStat4: {
      text: "Is the 10x increase in traffic on the websites of our clients.",
    },

    NexusDescription: {
      text: "A space where you can find information about the latest news in Technology, AI, Gaming, and more",
    },
    NexusDescription2: {
      text: "Our latest posts",
    },
    ReadMore: {
      text: "Read more",
    },

    ProjectsTitle: {
      text: "OUR WORK",
    },

    ProjectsText: {
      text: "Here you can see and review our work done for our dedicated clients.",
    },
    Project1Title: {
      text: "Delivery",
    },
    Project1Text: {
      text: "Website for a delivery company, with a modern and attractive design, with a real-time order tracking system.",
    },
    Project2Title: {
      text: "3D Site Web Demo",
    },
    Project2Text: {
      text: "A demo website built using a 3D design, with animations and a dynamic layout. (The website may not function properly on mobile devices because it was built using experimental technologies.)",
    },
    Project3Title: {
      text: "CR Construction",
    },
    Project3Text: {
      text: "Experience in building houses, apartments, offices, commercial premises, renovations, expansions, finishes, among others.",
    },
    Project4Title: {
      text: "Julio Carvajal",
    },
    Project4Text: {
      // Julio es filmaker, fotografo, editor de video y Piloto de FPV Drone
      text: "Website for Julio Carvajal, film maker, photographer, video editor and FPV Drone and DJI Drone pilot, as well as a payment system for his clients.",
    },
   
    Project6Title: {
      text: "Costa Brava Real Estate",
    },
    Project6Text: {
      text: "A Company with more than 15 years of experience in the real estate sector.",
    },
    Project7Title: {
      text: "Flor de Chocolate",
    },
    Project7Text: {
      text: "Automated virtual store for a business selling flowers made up of delicious strawberries with chocolate.",
    },

    VistitButton: {
      text: "Visit",
    },

    TechTitle: {
      text: "We work with the best technologies",
    },

    FAQTitle: {
      text: "Frequently <br/> asked questions",
    },
    FAQSubtitle: {
      text: "Here you can find the answers to the most frequent questions of our clients.",
    },
    FAQ1Title: {
      title: "How can I start a project with your company?",
      response:
        "You can contact us through our website, by phone, or email. We will be happy to discuss your needs, goals, and provide you with an initial consultation without any commitment.",
    },
    FAQ2Title: {
      title:
        "How long does it take to develop a custom application or website?",
      response:
        "The development time varies depending on the projects complexity. We conduct a detailed assessment upfront and provide an estimated timeline. Factors such as specific features and revisions can impact the project duration.",
    },
    FAQ3Title: {
      title: "What is your approach to data protection and user privacy?",
      response:
        "We take data protection and privacy very seriously. We implement robust security measures, adhere to privacy regulations, and work closely with our clients to ensure regulatory compliance.",
    },
    FAQ4Title: {
      // Pricing for services
      title:
        "How much does it cost to develop a custom application or website?",
      response:
        "The cost of a project varies based on complexity, scope, and specific requirements. We conduct a detailed assessment upfront and provide an estimated budget. Factors such as specific features and revisions can affect the project cost.",
    },
    FAQ5Title: {
      title: "Do you offer maintenance and support after the projects launch?",
      response:
        "Yes, we provide comprehensive support and maintenance services to ensure your project runs optimally. This includes updates, bug fixes, and technical assistance.",
    },
    FAQ6Title: {
      title: "How do you handle project scalability as they grow?",
      response:
        "We design our projects with scalability in mind. We use flexible architectures and technologies that allow seamless growth. Additionally, we offer consulting services to adjust the infrastructure as needed.",
    },

    FooterText: {
      text: "At WeCode, we specialize in developing tailor-made technological solutions to meet your needs, utilizing the latest technologies and incorporating a modern and appealing design.",
      about: "About Us",
      blog: "Blog",
      contact: "Contact",
      projects: "Projects",

      careers: "Careers",
      internship: "Internship",
      team: "Team",

      ia: "Artificial Intelligence",
      gaming: "Gaming",
      technology: "Technology",

      copyright:
        "© 2025 WeCodeCR. All rights reserved.",
      cancellation: "Cancellation Policy",
      refund: "Refund and Change Policy",
      privacy: "Privacy Policy",
      security: "Security Policy",
      policies: "Policies",
    },

    webDevelopment: {
      title: "Web Development",
      description:
        "For us, it is important that your website is unique, so we take care of creating a personalized design for your business with the goal of making your website stand out from the competition.",
      btn1: "Responsive (Adaptable)",
      btn2: "Custom Design",
      btn3: "Contact Form",
      statstitle1: "Increase",
      statstext1: "in visits and sales",
      statstitle2: "More than",
      statstext2: "sales from our clients",
      statstitle3: "Satisfaction",
      statstext3: "of our clients",

      cardtitle: "For any type of business or company",
      cardtext2: "Custom design",
      cardtext3:
        "Every client is unique, so we take care of creating a personalized design for your business.",
      cardtext4: "Increase in sales",
      cardtext5:
        "Whether you are an individual or a company, with a website, you can increase your sales.",
      cardtext6: "Best features",
      cardtext7:
        "Your website will have the best features, such as a contact form, a location map, live chat, animations, among others.",

      iconstext1: "Creative Development",
      iconstext2:
        "We create unique and attractive websites that adapt to your brand and target audience.",
      iconstext3: "Effortless Updates",
      iconstext4:
        "Benefit from automatic updates on all websites whenever you need to make a change.",
      iconstext5: "Custom Features",
      iconstext6:
        "We create custom features to make your website adapt to your needs.",
      iconstext7: "Conquer the Best",
      iconstext8:
        "With a professional website, your business will look more professional, allowing you to compete with the best.",
      iconstext9: "Safety First",
      iconstext10:
        "We implement the best security practices to protect valuable information on your website and for your users.",
      iconstext11: "User Experience",
      iconstext12:
        "We create engaging and user-friendly experiences to ensure visitor satisfaction.",

      ctatitle: "Are you ready to take your business to the clouds?",
      ctatext:
        "We offer the best prices on the market, in addition to providing you with a quality website, the best features, and technical support.",
      ctabtn: "Contact Us",
    },

    AppDevelopment: {
      title: "App Development",
      description:
        "We develop native mobile applications for Android and iOS using the latest technologies and with a modern and attractive design.",
      btn: "Contact Us",
      tech: "TECHNOLOGIES",

      features1: "Compatibility",
      features1Description:
        "We create native mobile applications for Android and iOS to ensure compatibility with all mobile devices.",
      features2: "Modern Design",
      features2Description:
        "We craft a modern and appealing design for your application to provide your users with a great user experience.",
      features3: "Documented Development",
      features3Description:
        "We document the entire development process of your application, allowing you to track the progress at every stage.",
      features4: "Technical Support",
      features4Description:
        "We provide technical support for your application, ensuring you can address any issues that may arise.",

      title2: "Why Develop a Mobile Application?",
      description2:
        "Mobile applications are an excellent choice for business growth, enabling you to reach more users and increase your sales.",

      title3: "How Does Mobile App Development Work?",
      description3:
        "Mobile app development is a process that involves multiple stages, from planning to the application launch.",

      features5: "Planning",
      features5Description:
        "In this stage, we collaborate with the client to define the objectives and requirements of the application.",
      features6: "Design",
      features6Description:
        "During this stage, we create the application design, allowing the client to visualize how the application will look.",
      features7: "Development",
      features7Description:
        "At this stage, we develop the application, providing the client with insights into the application's appearance.",
      features8: "Testing",
      features8Description:
        "We conduct thorough testing of the application to ensure its functionality meets expectations.",
      features9: "Quality Control",
      features9Description:
        "Quality control is implemented in this stage to maintain the applications high standards.",
      features10: "Launch",
      features10Description:
        "The application is launched in this stage, allowing the client to witness the final product.",

      ctatext:
        "We offer the best prices in the market, along with a quality application, top-notch features, and dedicated technical support.",
    },

    ecommerce: {
      title: "Online Stores | E-Commerce",
      description:
        "Digitize your business and sell your products online with a custom virtual store tailored to your needs.",

      img1: "Home and Decoration",
      img2: "Fashion and Clothing",
      img3: "Health and Beauty",
      img4: "Jewelry and Accessories",
      img5: "Electronics and Technology",

      title2: "Why develop your online store with us?",
      description2:
        "Online stores are an excellent option for your business to grow, allowing you to reach more users and increase your sales.",

      features1: "Custom Design",
      features1Description:
        "We create a custom design for your online store, making it unique and standing out from the competition.",
      features2: "Integration with Social Media",
      features2Description:
        "We integrate your online store with social media, allowing you to share your products on social platforms.",
      features3: "Payment Gateway Integration",
      features3Description:
        "We integrate your online store with payment gateways, enabling your customers to pay with credit or debit cards.",
      features4: "Inventory Management",
      features4Description:
        "Manage your products, inventory, and sales from one place, making it easy and quick to administer your online store.",
      features5: "Product Shipping",
      features5Description: "Control your shipments and track your packages.",
      features6: "Support",
      features6Description:
        "We provide technical support for your online store, helping you resolve any issues you may encounter.",

      title3: "How simple it is to have your own online store",
      step1: "Planning",
      step1Description:
        "We meet with the client to define the goals and requirements of the online store.",
      step2: "Design",
      step2Description:
        "Your store should be unique, so we take care of designing it according to your needs.",
      step3: "Payment Methods Integration",
      step3Description:
        "Accept payments with credit cards, debit cards, PayPal, and other payment methods.",
      step4: "Launch",
      step4Description:
        "Your store is ready! Now, you just need to promote it to reach more customers.",
    },

    CustomSoftware: {
      title: "Custom Software",
      description:
        "We help businesses bring their ideas into the digital world by designing and implementing the technological tools they need to succeed.",
      svg1: "Enhance",
      svg2: "Versatility",
      svg3: "Efficiency",

      title2: "We Help You Achieve Your Goals",
      description2:
        "With custom software, you can create a solution that fits your specific needs. Whether you are developing an internal application or a product for the market, custom software can help you achieve your business objectives.",
      features1: "Designed for You or Your Company",
      features1Description:
        "Created to meet the specific needs of your company, adapting to your unique requirements and business processes. This can help you achieve greater efficiency and productivity in the workplace.",
      features2: "Scalable and Flexible",
      features2Description:
        "Easily scalable and adaptable as your business grows and changes. This allows you to add new features and capabilities as needed, providing the flexibility to adapt to market changes.",
      features3: "Secure and Reliable",
      features3Description:
        "More secure than commercial software, designed to meet your specific needs. This means no unnecessary features that could be vulnerable to cyber-attacks, making your software more secure and reliable.",

      title3: "Why Choose Us?",
      description3:
        "Our team of software development experts can help you create a solution that fits your specific needs. Whether you are creating an internal application or a product for the market, we can help you achieve your business goals.",
      features4: "Custom Development, Extraordinary Results",
      features4Description:
        "Our team of software development experts can help you create a solution that fits your specific needs. Whether you are creating an internal application or a product for the market, we can help you achieve your business goals.",
      features5: "Efficiency that Drives Your Competitiveness",
      features5Description:
        "Every company is different, so you need a solution that fits your specific needs. Our team of software development experts can help you create a solution that fits your specific needs.",
      features6: "Design, Scalability, and Reliability Without Compromise",
      features6Description:
        "We are ready for the next step in your business journey. That's why every software we create is designed to be scalable and reliable, providing the flexibility to adapt to changes in the market.",

      title4: "Examples of <br />Software",
      title41: "Customized",
      title5: "Customized Management System",
      description5: `We develop customized systems for companies that optimize internal processes. <br />
              Examples include: <br />
              - Exclusive platform for employee management <br />
              - Human resources management tools tailored to specific needs <br />
              - Solutions for project and task tracking <br />`,

      title6: "Customized Customer and Employee Management System",
      description6: `We develop customized systems to efficiently manage clients and employees. <br />
              Examples include: <br />
              - Exclusive platform for managing client and employee profiles <br />
              - Customized solutions for tracking sales and client histories <br />
              - Tools tailored for sales records and performance analysis <br />`,

      title7: "Custom Tools for Business Efficiency",
      description7: `We develop custom software to optimize processes and improve operational efficiency. <br />
              Examples include: <br />
              - Automation tools tailored to specific business processes <br />
              - Systems for tracking and improving internal productivity <br />
              - Project management platforms designed according to specific needs <br />`,

      faqtitle: "Frequently Asked Questions",
      faq1: "How do you ensure the quality of the software?",
      faq1Description:
        "We conduct thorough testing at all stages of development. Additionally, we encourage continuous feedback to ensure the final product meets your quality expectations.",
      faq2: "How scalable is the software you develop?",
      faq2Description:
        "We develop highly scalable solutions that can grow with your business. They can expand to incorporate new features and capabilities as your needs evolve.",
      faq3: "Can you integrate the new software with my existing systems?",
      faq3Description:
        "Yes, we design solutions that seamlessly integrate with your existing systems. Interoperability is key to ensuring a smooth transition.",
      faq4: "What kind of support do you offer after implementing the software?",
      faq4Description:
        "We provide continuous support, including updates, maintenance, and technical assistance. We are committed to ensuring your software operates optimally over time.",
      faq5: "Can you develop mobile applications as part of the custom services?",
      faq5Description:
        "Yes, we offer custom mobile application development that seamlessly integrates with your systems and business objectives.",
      faq6: "How do you handle changes in regulations and industry standards that may affect the software?",
      faq6Description:
        "We maintain constant vigilance over regulatory changes and update your software according to new requirements to ensure ongoing compliance.",
    },

    businessSoftware: {
      title1: "Business Software",
      title2: "The Solution Your Company Needs",
      subtitle1: "Recommended by Our Clients",
      text1: "100+ Satisfied Clients",

      card1title: "Websites, Portals, and Web Platforms",
      card1text:
        "We develop websites, portals, and web platforms tailored to your needs.",
      card2title: "Mobile Applications",
      card2text:
        "We develop mobile applications for Android and iOS tailored to your needs.",
      card3title: "Process Automation",
      card3text:
        "We automate processes in your company so you can dedicate your time to what really matters.",

      title3: "Why Are We",
      title3b: "the Best Choice?",
      description3:
        "We provide the best solutions to our clients, from design to implementation. Our team is highly qualified to offer you the best attention and service.",
      features1: "24/7 Support",
      features1Description:
        "We have a technical support team available 24 hours a day, 7 days a week.",
      features2: "Custom Design",
      features2Description:
        "Our designs are unique and tailored to your needs and requirements.",
      features3: "Cutting-Edge Technology",
      features3Description:
        "We use the best technologies for the development of your projects, such as React, NextJS, NodeJS, MongoDB, among others.",
      features4: "Data Analytics",
      features4Description:
        "We analyze your companys data to provide you with the best solutions so you can make the best decisions.",
      features5: "Security",
      features5Description:
        "Our servers have the best security measures to protect your data, with a security team capable of working with laws such as COPPA, GDPR, HIPAA, among others.",
      features6: "Maintenance",
      features6Description:
        "We offer maintenance plans to keep your website, mobile application, or web platform always updated and in optimal conditions.",
      features7: "Sustainable Development",
      features7Description:
        "Our developments are designed to be scalable and sustainable over time.",
      features8: "Existing Systems",
      features8Description:
        "If you have an already developed system and want to update, improve, or add new functionalities, we can help you.",
    },

    apis: {
      title: "Development of",
      title1: "APIs",
      description:
        "With API development, we can create a connection between your application and other third-party applications, such as social networks, banks, among others. This way, we can achieve integration between your application and other third-party applications.",
      title2: "Why",
      title2b: "APIs",
      title2c: "are so",
      title2d: "important for your application?",
      description2:
        "APIs are a fundamental part of any modern application. They are the way applications communicate with each other.",
      features1: "Documentation",
      features1Description:
        "By creating an API, we can document it so that other developers can use it. This is crucial for other developers to understand it.",
      features2: "Security",
      features2Description:
        "We protect all information sent through the API with 256-bit encryption. This is essential to prevent information theft.",
      features3: "Integration",
      features3Description:
        "By creating an API, we can integrate it with other third-party applications. This is crucial for other applications to leverage it.",
      features4: "Scalability",
      features4Description:
        "By creating an API, we can scale it to handle more traffic. This is essential to prevent the application from crashing or failing.",

      faqtitle: "Frequently Asked Questions",
      faq1: "What is an API?",
      faq1Description:
        "APIs allow applications and services to communicate in a standardized way, facilitating integration between different systems. They provide a set of functions and procedures that allow developers to access the features or data of an application, service, or platform without needing to know the internal details of its implementation.",
      faq2: "How does an API benefit me?",
      faq2Description:
        "They benefit businesses, enterprises, and institutions by enabling smooth integration between systems, accelerating development by reusing existing functions, facilitating access to external services, improving the user experience, fostering innovation through external collaboration, offering flexibility and scalability, and enabling efficient collaboration with business partners.",
      faq3: "What is the cost of an API?",
      faq3Description:
        "The cost of an API depends on its complexity and varies based on factors such as the number of endpoints, data, users, among others.",
      faq4: "What is an endpoint?",
      faq4Description:
        "An endpoint is an access point to an API; it is the URL to be called to get a response from the API. Endpoints can be of different types, for example, to get, send, update, or delete data, among others.",
      faq5: "How is an API protected?",
      faq5Description:
        "APIs are protected by 256-bit encryption, making the sent information unreadable to anyone without the encryption key.",
      faq6: "How much does it cost to maintain an API?",
      faq6Description:
        "The maintenance cost of an API depends on factors such as the number of users, data, endpoints, among others.",
    },

    anotherServices: {
      title: "Digital solutions to take your business to the",
      title1: "next level",
      description:
        "At WeCode, we specialize in developing digital solutions tailored to the needs of your business, enabling you to take it to the next level.",
      subtitle1: "Consulting",
      title2: "Need help with your project?",
      description2:
        "Through our consulting services, we assist you in defining the best strategy for your project, ensuring its successful execution.",

      features1: "Strategy",
      features1Description:
        "We help you define the best strategy for your project to ensure its successful execution.",
      features2: "Analysis",
      features2Description:
        "We analyze your project and assist in defining the optimal strategy for its execution.",
      features3: "Planning",
      features3Description:
        "We aid in planning your project, whether from scratch or improving an existing one.",
      features4: "Execution",
      features4Description:
        "If you have uncertainties about project execution, we guide you in implementing it effectively.",

      title3: "SEO Services",
      card1title: "Content Optimization",
      card1text:
        "We analyze your content and assist in optimizing it to be search engine-friendly.",
      card2title: "SEO Analysis and Audit",
      card2text:
        "We assess your website and help establish an SEO strategy to enhance search engine rankings.",
      card3title: "Technical Optimization",
      card3text:
        "We analyze your website's code and help optimize or improve it to be more search engine-friendly.",

      title4: "Maintenance and Technical Support",
      subtitle4: "We assist in keeping your website",
      subtitle4b: "always updated",
      description4:
        "With our maintenance and technical support service, we help keep your website always updated, ensuring it functions correctly at all times.",
      features5: "Preventive Maintenance",
      features5Description:
        "Through preventive maintenance, your website stays updated and operates correctly, maintaining stability and security.",
      features6: "System Monitoring",
      features6Description:
        "By monitoring the system, we can detect and address any issues before they escalate.",
      features7: "Documentation Updates",
      features7Description:
        "With documentation updates, you always have current information about your website available when needed.",
      features8: "Problem Resolution",
      features8Description:
        "Addressing issues ensures your website runs smoothly, regardless of the problem encountered.",
    },

    ia: {
      title: "The future of business is",
      title1: "Artificial Intelligence",
      description:
        "In 2023, 60% of application development initiatives will include co-developed AI. AI is a versatile technology applicable to any sector, helping businesses solve problems more efficiently and make better decisions.",

      title2: "How can AI be used in your business?",
      description2:
        "AI can assist businesses in automating repetitive tasks, enhancing customer experience, and making smarter decisions.",

      subtitle1: "Chatbots",
      description3:
        "Chatbots are software programs capable of engaging in conversations with users. They can be used to answer frequently asked questions, provide information about products and services, or even assist customers in real-time.",

      features1: "Custom Virtual Assistants (GPTs)",
      features1Description:
        "With the latest GPTs, we can create custom virtual assistants for your business. These assistants can help customers find what they're looking for, answer their questions, and educate them about your business, products, and services.",
      features2: "Automated Content Development",
      features2Description:
        "AI can help businesses create automated content for their websites, blogs, and social media. This can save time and money, eliminating the need to hire content writers or editors.",
      features3: "Data Analysis and Report Generation",
      features3Description:
        "With a custom GPT, we can analyze your company's data and generate reports on the results. This can assist you in making smarter decisions and improving your business.",

      subtitle2: "With AI, your business can be more efficient and profitable",
      description4:
        "Companies are implementing AI across all areas of their business, from customer service to inventory management. AI can help businesses become more efficient and profitable.",

      features4: "Predictive Analysis and Market Trends",
      features4Description:
        "AI can help businesses predict market trends and make smarter decisions about their products and services. This can contribute to making businesses more efficient and profitable.",
      features5: "Social Media Response Automation",
      features5Description:
        "Let AI handle responding to your customers on social media. We can create a custom GPT for your business that can answer your customers' questions and help them resolve issues.",
      features6: "Image Generation and Recognition",
      features6Description:
        "Product design, image recognition, image generation, etc. We can help your company use AI to create customized images for your products and services.",

      examples: "Usage Examples",
      description5: "Real-world examples of artificial intelligence usage.",
      example1: "Data Wizard and Analyst",
      example1Description:
        "Expert in various data analysis and visualization, providing detailed statistics.",
      try1: "Try Data Wizard and Analyst",
      example2: "Digital Ad Creator",
      example2Description:
        "Digital marketing expert for creating advertising images and texts for social media.",
      try2: "Try Digital Ad Creator",
      example3: "Color Ideas",
      example3Description:
        "Color Ideas is a custom GPT that generates coloring book illustrations based on a text description.",
      try3: "Try Color Ideas",
      example4: "Tech Helper",
      example4Description:
        "Technical support expert offering step-by-step guidance for device setup and problem resolution.",
      try4: "Try Tech Helper",
    },

    team: {
      HeroTeamsBar: "Meet our team",
      HeroTitle1: "Everything you",
      HeroTitle2: "can imagine",
      HeroTitle3: "we can create it",
      HeroSubtitle:
        "We are a team of highly trained and innovative professionals who work closely with you to understand your needs and develop unique, effective technological solutions.",
      HeroButton: "Contact",
      BarMissionandVision: "Our Mission and Vision",
      TitleMissionandVision:
        "We innovate to create technological solutions to measure of your business",
      SubtitleMissionandVision:
        "Our mission is to provide our clients with the best technological solutions, with the aim of improving their productivity and competitiveness. Our vision is to be the leading company in the development of technological solutions in Costa Rica.",
      CompanyValues: "Company Values",
      CompanyValuesDescription:
        "Our values are the pillars that support our company and guide our actions. They are the essence of our identity as a company and represent the beliefs and principles that guide our behavior.",
      OurMission: "Our Mission",
      OurMissionDescription:
        "Our mission is to provide our clients with the best technological solutions, with the aim of improving their productivity and competitiveness.",
      OurVision: "Our Vision",
      OurVisionDescription:
        "Our vision is transforming the world through technology, being the leading company in the development of technological solutions from Costa Rica to the world.",

      ctatitle: "Are you ready to take your business to the clouds?",
      ctatext:
        "We offer the best prices on the market, in addition to providing you with a quality website or software, the best features, and technical support.",
      ctabtn: "Contact Us",
    },

    contact: {
      ContactSub: "Contact Us",
      ContactTitle: "Do you have any questions or inquiries?",
      ContactText:
        "If you have any questions or inquiries, dont hesitate to contact us.",
      ContactName: "Name",
      ContactEmail: "Email",
      ContactPhone: "Phone (Optional)",
      ContactMessage: "Message",
      ContactButton: "Send Message",
      contactSuccess: "Message sent successfully",
      ContactError: "Error sending the message. Please try again later",
      ErrorCatpcha: "Please verify that you are not a robot",
    },

    promo: {
      // Hero section
      title: "Limited Time Offer",
      subtitle: "Digitize your business with a professional website",
      subtitle2: "for just $50 monthly",
      subtitle3: "All inclusive!",
      subtitle4: "Discover all the benefits included in this special offer",

      // Steps section
      stepTitle: "What's included?",
      
      step1: "1",
      step1Title: "Complete Website",
      step1Description: "Full website development, either in WordPress or custom code. The code remains WeCode property.",
      
      step2: "2",
      step2Title: "Hosting & Email",
      step2Description: "Starter Plan with 50 GB SSD, 25 MariaDB databases, 50 business emails with server's own platform.",
      
      step3: "3",
      step3Title: "Maintenance",
      step3Description: "2 monthly maintenance sessions including security, performance and content updates as needed.",
      
      step4: "4",
      step4Title: "Commitment",
      step4Description: "6-month minimum contract, automatic monthly payment via Fygaro. Cancellation available after commitment period.",

      // Features in steps
      features: {
        website: [
          "WordPress or Custom Code",
          "Responsive Design",
          "SEO Optimized"
        ],
        hosting: [
          "50 GB SSD",
          "50 Business Emails",
          "25 MariaDB Databases"
        ],
        maintenance: [
          "Security Updates",
          "Performance Optimization",
          "Content Updates"
        ],
        commitment: [
          "6-month minimum contract",
          "Monthly payment via Fygaro",
          "Technical support included"
        ]
      },

      // CTA Image section
      ctaTitle: "Important Note",
      additionalCosts: {
        title: "Additional Costs",
        description: "Domain (annual payment) and payment gateways for online stores are not included in the monthly fee."
      },
      commitmentPeriod: {
        title: "Commitment Period",
        description: "6-month minimum contract with automatic monthly payment via Fygaro."
      },
      codeOwnership: {
        title: "Code Ownership",
        description: "The code is WeCode property. If you want to acquire it, it will have an additional cost."
      },
      ctaButton: "I want to take advantage of this offer!",

      // Examples section
      examples: {
        title: "Website Examples",
        subtitle: "Discover some examples of what you can get with this offer",
        
        corporate: {
          title: "Corporate Website",
          description: "Perfect for companies seeking professional online presence",
          features: [
            "Professional and modern design",
            "Informative pages",
            "Contact form",
            "Social media integration"
          ]
        },
        store: {
          title: "Online Store",
          description: "Perfect for businesses wanting to sell products online",
          features: [
            "Product catalog",
            "Shopping cart",
            "Payment gateway",
            "Inventory management"
          ]
        },
        services: {
          title: "Service Website",
          description: "Excellent for professionals and service companies",
          features: [
            "Service portfolio",
            "Appointment system",
            "Integrated blog",
            "Client testimonials"
          ]
        },
        landing: {
          title: "Landing Page",
          description: "Ideal for specific campaigns and promotions",
          features: [
            "Conversion-optimized design",
            "Lead capture forms",
            "Effective call-to-actions"
          ]
        }
      },

      // Hero badges
      badges: {
        website: "Complete Website",
        hosting: "Hosting Included",
        maintenance: "Maintenance",
        email: "Business Email"
      },

      // Banner translations
      bannerTitle: "Limited time offer!",
      bannerSubtitle: "Take advantage of this unique opportunity to digitize your business with a professional website and included maintenance.",
      bannerButton: "Contact now",
    },

    careers: {
      title: "Join Our Team",
      subtitle: "Explore available opportunities and be part of our mission",
      
      // Search
      searchPlaceholder: "Search by title or keyword",
      location: "Location",
      jobType: "Job Type",
      searchButton: "Search",
      
      // Filters
      filterTitle: "Filters",
      clearFilters: "Clear filters",
      
      // Job Types
      jobTypes: {
        all: "All types",
        fullTime: "Full Time",
        partTime: "Part Time",
        temporary: "Temporary",
        commission: "Commission",
        perHour: "Per Hour"
      },
      
      // Locations
      locations: {
        all: "All locations",
        remote: "Remote",
        heredia: "Heredia",
        sanJose: "San Jose"
      },
      
      // Job Card
      postedOn: "Posted on",
      applyNow: "Apply Now",
      viewDetails: "View Details",
      
      // Job Modal
      jobDetails: "Job Details",
      jobDescription: "Description",
      requirements: "Requirements",
      benefits: "Benefits",
      close: "Close",
      
      // Application Form
      applicationForm: {
        title: "Apply for",
        name: "Full Name",
      email: "Email",
        phone: "Phone",
        resume: "Resume (PDF)",
        coverLetter: "Cover Letter",
        submit: "Submit Application",
        dragDrop: "Drag and drop your file here or",
        browse: "browse",
        maxSize: "Maximum size: 5MB"
      },
      
      // Messages
      noJobsFound: "No jobs found matching your search",
      applicationSuccess: "Your application has been submitted successfully",
      applicationError: "There was an error submitting your application. Please try again",
      
      // Benefits Section
      benefitsSection: {
        title: "Benefits of Working With Us",
        subtitle: "We offer an excellent work environment and competitive benefits",
        benefits: [
          {
            title: "Remote Work",
            description: "Flexibility to work from wherever you prefer"
          },
          {
            title: "Flexible Schedule",
            description: "Work-life balance"
          },
          {
            title: "Professional Development",
            description: "Growth opportunities and continuous training"
          },
          {
            title: "Innovative Environment",
            description: "Work with the latest technologies and methodologies"
          }
        ]
      },
      
      // Hero Section
      heroTitle: "Join Our Team of Innovators",
      heroSubtitle: "Build your career at a leading technology company",
      heroStats: {
        openPositions: "Open Positions",
        countries: "Countries",
        remote: "Remote Work"
      },
      
      // CTA Section
      ctaTitle: "Ready to Take the Next Step?",
      ctaSubtitle: "Join a team passionate about technology and development. You'll work on challenging projects with the latest technologies.",
      ctaButton: "View open positions",

      // "Why Join WeCode?" Section
      whyJoinTitle: "Why Join WeCode?",
      whyJoinSubtitle: "Join a team passionate about technology and development. You'll work on challenging projects with the latest technologies.",
      
      whyJoinReasons: {
        collaboration: "Collaborative work environment",
        innovation: "Innovative projects",
        technology: "Cutting-edge technologies",
        growth: "Growth opportunities"
      },

      // "Our Culture" Section
      cultureTitle: "Our Culture",
      cultureDescription: "At WeCode, we foster a culture of innovation, collaboration, and continuous learning. We value diversity of ideas and promote an environment where each team member can grow and develop their full potential.",
      
      cultureValues: {
        innovation: {
          title: "Innovation",
          description: "We encourage new ideas and creative solutions"
        },
        collaboration: {
          title: "Collaboration",
          description: "We work together to achieve common goals"
        },
        excellence: {
          title: "Excellence",
          description: "We strive for quality in everything we do"
        }
      },

      // Statistics
      stats: {
        professionals: {
          number: "15+",
          title: "Professionals",
          description: "Talented and diverse team"
        },
        projects: {
          number: "100+",
          title: "Projects",
          description: "Successfully completed"
        },
        countries: {
          number: "15+",
          title: "Countries",
          description: "Clients around the world"
        }
      },

      // Selection Process
      selectionProcess: {
        title: "Selection Process",
        steps: [
          {
            step: "1",
            title: "Application",
            description: "Submit your CV and cover letter"
          },
          {
            step: "2",
            title: "Review",
            description: "We evaluate your profile and experience"
          },
          {
            step: "3",
            title: "Interviews",
            description: "We learn more about you and your skills"
          },
          {
            step: "4",
            title: "Decision",
            description: "We communicate the result to you"
          }
        ]
      },

      // Frequently Asked Questions
      faq: {
        title: "Frequently Asked Questions",
        questions: [
          {
            q: "Do you offer remote work?",
            a: "Yes, most of our positions are 100% remote."
          },
          {
            q: "What is the interview process?",
            a: "We conduct technical and cultural interviews to ensure the best fit."
          },
          {
            q: "What benefits do you offer?",
            a: "We offer flexible schedules, professional development, and more."
          }
        ]
      }
    },

    cancellation: {
      title: "Cancellation Policies",
      subtitle: "Important information about our service cancellation policies",
      
      conditionsTitle: "Conditions",
      conditionsDescription: "To request a service cancellation, the following conditions must be met:",
      conditions: [
        "Service payments must be up to date",
        "Must notify in advance according to established deadlines",
        "Must not have active contracts or pending commitments",
        "Must have completed the minimum contracted service period"
      ],

      timeTitle: "Timeframes",
      timeDescription: "Cancellation request timeframes vary by service type:",
      times: [
        {
          service: "Hosting Services",
          period: "30 days in advance"
        },
        {
          service: "Maintenance Services",
          period: "15 days in advance"
        },
        {
          service: "Development Services",
          period: "As established in the contract"
        }
      ],

      procedureTitle: "Procedure",
      procedureDescription: "To cancel a service, follow these steps:",
      procedures: [
        "1. Send a formal written request to contact@wecodecr.com",
        "2. Include your account information and the service you wish to cancel",
        "3. Specify the reason for cancellation",
        "4. Our team will review your request within 48 hours",
        "5. You will be notified of the resolution by email"
      ],

      note: "Note: Service cancellation may be subject to charges as established in your contract.",
      
      contact: {
        title: "Need Help?",
        description: "If you have questions about the cancellation process, don't hesitate to contact us:",
        email: "contact@wecodecr.com",
        phone: "+506 7060-2186"
      }
    },

    refund: {
      title: "Refund and Change Policies",
      subtitle: "Important information about our service refund and change policies",
      
      conditionsTitle: "Conditions",
      conditionsDescription: "To request a refund or service change, the following conditions must be met:",
      conditions: [
        "Request must be made within the first 30 days of service contracting",
        "The service must not have been fully utilized",
        "For software or web development, the project must not have exceeded the design phase",
        "For hosting services, must not have exceeded 50% of resource usage",
        "Does not apply to already registered domains or customized services already implemented"
      ],

      timeTitle: "Timeframes",
      timeDescription: "Timeframes for requesting a refund or change vary by service type:",
      times: [
        {
          service: "Hosting and Servers",
          period: "30 days from contracting",
          details: "100% refund if unused, 50% if partially used"
        },
        {
          service: "Web and Software Development",
          period: "15 days from project start",
          details: "70% refund if in initial phase, not applicable in advanced phases"
        },
        {
          service: "Maintenance and Support",
          period: "7 days from last renewal",
          details: "Proportional refund for unused time"
        },
        {
          service: "Digital Marketing Services",
          period: "Before campaign start",
          details: "90% refund if implementation hasn't started"
        }
      ],

      procedureTitle: "Procedure",
      procedureDescription: "To request a refund or change, follow these steps:",
      procedures: [
        "1. Send a formal written request to contact@wecodecr.com",
        "2. Include your account information and specific service",
        "3. Detail the reason for the refund or change request",
        "4. Attach any relevant documentation supporting your request",
        "5. Our team will evaluate your case within 72 business hours",
        "6. You will be notified of the resolution by email"
      ],

      exclusionsTitle: "Exclusions",
      exclusions: [
        "Already registered web domains",
        "Custom services already implemented",
        "Already activated third-party software licenses",
        "Fully consumed services",
        "Projects in advanced development phase (more than 50% completed)"
      ],

      changesTitle: "Change Policy",
      changesDescription: "Available options for service changes:",
      changes: [
        {
          type: "Plan Change",
          description: "You can upgrade or change your plan at any time, applying the price difference",
          conditions: "The new plan must have a value equal to or higher than the current one"
        },
        {
          type: "Feature Change",
          description: "Modification of specific service features",
          conditions: "Sujeto a evaluación técnica y diferencia de costos"
        },
        {
          type: "Service Transfer",
          description: "Transferencia del servicio a otro titular",
          conditions: "Ambas partes deben estar de acuerdo y al día en pagos"
        }
      ],

      note: "Note: All refunds are subject to review and administrative charges may apply depending on the case.",
      
      contact: {
        title: "Need Help?",
        description: "If you have questions about refunds or changes, contact us:",
        email: "contact@wecodecr.com",
        phone: "+506 7060-2186",
        support: "Support available Monday to Friday, 9:00 AM - 5:00 PM"
      }
    },

    privacy: {
      title: "Privacy Policy",
      subtitle: "Information about how we protect and handle your personal data",
      
      dataUsageTitle: "Data Usage",
      dataUsageDescription: "Information about how we use your personal data:",
      dataUsage: [
        {
          type: "Personal Data",
          data: ["Name", "Email", "Phone number"],
          purpose: "Used exclusively for communication related to contracted services and technical support"
        },
        {
          type: "Payment Data",
          data: ["Card number", "Expiration date", "Security code"],
          purpose: "Securely processed through certified payment gateways, without storage on our servers"
        },
        {
          type: "Technical Information",
          data: ["IP Address", "Browser type", "Operating system"],
          purpose: "Used to improve user experience and service security"
        }
      ],

      securityTitle: "Security and Protection",
      securityDescription: "Measures implemented to protect your information:",
      security: [
        {
          title: "SSL Certificate",
          description: "All our pages are protected with 256-bit SSL certificates",
          verification: "Verify the green padlock in your browser's address bar"
        },
        {
          title: "Data Encryption",
          description: "All sensitive information is transmitted in encrypted form",
          details: "We use bank-level encryption protocols"
        },
        {
          title: "Secure Storage",
          description: "Data is stored on secure servers with restricted access",
          details: "We implement multiple security layers and regular backups"
        }
      ],

      transactionsTitle: "Transaction Privacy",
      transactionsDescription: "Guarantees about your transaction privacy:",
      transactions: [
        "All transactions are processed through secure and certified payment gateways",
        "Payment information is never stored on our servers",
        "Transactions are encrypted and cannot be viewed by third parties",
        "We use tokenization to protect card data",
        "We comply with PCI DSS standards for payment processing"
      ],

      protectionTitle: "Protection Measures",
      protectionDescription: "How we protect your privacy:",
      protection: [
        {
          measure: "Access Control",
          description: "Restricted access to personal data only to authorized personnel"
        },
        {
          measure: "Continuous Monitoring",
          description: "24/7 intrusion detection and prevention systems"
        },
        {
          measure: "Regular Audits",
          description: "Periodic security and privacy assessments"
        },
        {
          measure: "Security Updates",
          description: "Constant maintenance and security updates"
        }
      ],

      dataRightsTitle: "Your Rights",
      dataRights: [
        "Right to access your personal data",
        "Right to rectify incorrect information",
        "Right to request deletion of your data",
        "Right to limit the processing of your information",
        "Right to data portability"
      ],

      verificationTitle: "Security Verification",
      verificationSteps: [
        "Verify the security certificate (green padlock) in your browser",
        "Confirm that the URL starts with 'https://'",
        "Check that you are on the correct domain (wecodecr.com)",
        "No share your access credentials",
        "Use secure and unique passwords"
      ],

      contact: {
        title: "Privacy Matters Contact",
        description: "If you have questions about our privacy policy or data handling, contact us:",
        email: "privacy@wecodecr.com",
        phone: "+506 7060-2186",
        support: "Query attention: Monday to Friday, 9:00 AM - 5:00 PM"
      }
    },

    security: {
      title: "Security Policy",
      subtitle: "Information about our security measures and data protection",
      
      dataEntryTitle: "Secure Information Entry",
      dataEntryDescription: "Protection of sensitive data during entry:",
      dataEntry: [
        {
          type: "Personal Data",
          data: ["Name", "Address", "Phone", "Email"],
          protection: "Encrypted transmission via SSL protocol"
        },
        {
          type: "Payment Data",
          data: ["Card number", "Expiration date", "CVV"],
          protection: "Secure processing through PCI DSS certified payment gateways"
        },
        {
          type: "Credentials",
          data: ["Username", "Password", "Access tokens"],
          protection: "Encrypted storage and secure password hashing"
        }
      ],

      sslTitle: "SSL Security Certificate",
      sslDescription: "Our SSL certificate provides:",
      ssl: [
        {
          feature: "128-bit Encryption",
          description: "Robust protection for all communications between your browser and our servers"
        },
        {
          feature: "Extended Validation (EV)",
          description: "Rigorous verification of our company's identity"
        },
        {
          feature: "Security Seal",
          description: "Certificate issued by trusted certification authority"
        }
      ],

      serverSecurityTitle: "Server Security",
      serverSecurityDescription: "Protection measures implemented on our servers:",
      serverSecurity: [
        {
          measure: "Advanced Firewall",
          description: "Protection against intrusions and malicious attacks",
          details: "24/7 monitoring and automatic security updates"
        },
        {
          measure: "Automatic Backups",
          description: "Daily encrypted backups",
          details: "Redundant storage in multiple locations"
        },
        {
          measure: "Access Control",
          description: "Two-factor authentication for administrative access",
          details: "Detailed logs of all activities"
        }
      ],

      protocolsTitle: "Security Protocols",
      protocolsDescription: "We implement multiple security layers:",
      protocols: [
        "Continuous monitoring of suspicious activities",
        "Intrusion Detection and Prevention (IDS/IPS)",
        "Regular vulnerability analysis",
        "Automatic security updates",
        "DDoS protection"
      ],

      complianceTitle: "Compliance and Certifications",
      compliance: [
        {
          standard: "PCI DSS",
          description: "Compliance with payment card data security standards"
        },
        {
          standard: "HTTPS",
          description: "Secure communications through TLS 1.3"
        },
        {
          standard: "OWASP",
          description: "Following web security best practices"
        }
      ],

      verificationTitle: "Security Verification",
      verificationSteps: [
        "Verify the security padlock in your browser",
        "Confirm that the URL starts with 'https://'",
        "Check the SSL certificate validity",
        "Do not share your access credentials",
        "Report any suspicious activity"
      ],

      contact: {
        title: "Security Contact",
        description: "To report security issues or vulnerabilities:",
        email: "security@wecodecr.com",
        phone: "+506 7060-2186",
        support: "Security team available 24/7"
      }
    },

    // Projects
    Project8Title: {
      text: "Rojas Motors CR",
    },
    Project8Text: {
      text: "Online store specialized in automotive parts. Customers can search, compare and purchase parts directly from the platform, with an intelligent catalog system and integrated payments.",
    },
    Project9Title: {
      text: "EckoTraveler",
    },
    Project9Text: {
      text: "Booking platform for tours and adventures. Travelers can explore destinations, view trip details, costs and make reservations with secure online payments.",
    },
  },
};
export default en;

