import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Check } from "lucide-react";

export default function PricingTable() {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAnnual, setIsAnnual] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      const targetUrl = 'https://hosting.wecodecr.com/api/products/WEBHOSTING';
      try {
        const proxyUrl = 'https://corsproxy.io/?';
        const response = await axios.get(proxyUrl + encodeURIComponent(targetUrl));
        setPlans(response.data.data);
        setLoading(false);
      } catch (err) {
        try {
          const backupProxyUrl = 'https://api.allorigins.win/raw?url=';
          const backupResponse = await axios.get(backupProxyUrl + encodeURIComponent(targetUrl));
          setPlans(backupResponse.data.data);
          setLoading(false);
        } catch (backupErr) {
          setError('Error al cargar los planes');
          setLoading(false);
        }
      }
    };
    
    fetchPlans();
  }, []);

  const handleCheckout = (plan) => {
    navigate("/hosting/checkout", { 
      state: { 
        plan,
        billingCycle: isAnnual ? 'annual' : 'monthly'
      } 
    });
  };

  if (loading) return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );

  if (error) return (
    <div className="text-center text-red-600 p-4">{error}</div>
  );

  return (
    <section id="plans" className="text-gray-700 body-font mt-10 overflow-hidden">
      <div className="flex flex-col gap-2 lg:gap-4 container mx-auto max-w-7xl px-4">
        <h1 className="text-4xl font-bold text-center">
          Planes de Hosting Web
        </h1>
        <p className="text-gray-600 text-center mb-8">
          Soluciones de hosting optimizadas para tu sitio web
        </p>

        {/* Toggle de facturación */}
        <div className="flex justify-center items-center gap-4 mb-12">
          <span className={`text-lg ${!isAnnual ? 'text-blue-600 font-semibold' : 'text-gray-500'}`}>
            Mensual
          </span>
          <button
            onClick={() => setIsAnnual(!isAnnual)}
            className="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 bg-gray-200"
            role="switch"
            aria-checked={isAnnual}
          >
            <span
              className={`${
                isAnnual ? 'translate-x-5' : 'translate-x-0'
              } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
            />
          </button>
          <span className={`text-lg ${isAnnual ? 'text-blue-600 font-semibold' : 'text-gray-500'}`}>
            Anual (20% descuento)
          </span>
        </div>

        {/* Tabla de precios */}
        <div className="w-full overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="p-4 text-left bg-gray-50 border-b"></th>
                {plans.map((plan) => (
                  <th key={plan.ProductID} className={`p-4 text-center border-b min-w-[200px]
                    ${plan.Featured ? 'bg-blue-50' : 'bg-gray-50'}`}>
                    <div className="space-y-2">
                      {plan.Featured && (
                        <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
                          Recomendado
                        </span>
                      )}
                      <h3 className="text-xl font-bold">{plan.Title}</h3>
                      <div className="text-3xl font-bold">
                        ${isAnnual ? (plan.BasePrice * 12 * 0.8).toFixed(2) : plan.BasePrice}
                        <span className="text-sm font-normal text-gray-500">
                          /{isAnnual ? 'año' : 'mes'}
                        </span>
                      </div>
                      <p className="text-sm text-gray-500">{plan.Description}</p>
                      <button
                        onClick={() => handleCheckout(plan)}
                        className={`w-full py-2 px-4 rounded-lg font-medium transition-colors
                          ${plan.Featured 
                            ? 'bg-blue-600 text-white hover:bg-blue-700' 
                            : 'bg-gray-800 text-white hover:bg-gray-900'}`}
                      >
                        Seleccionar
                      </button>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-4 font-medium border-b">Rendimiento</td>
                {plans.map((plan) => (
                  <td key={plan.ProductID} className={`p-4 text-center border-b
                    ${plan.Featured ? 'bg-blue-50' : ''}`}>
                    {plan.features.Performance}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="p-4 font-medium border-b">Sitios Web</td>
                {plans.map((plan) => (
                  <td key={plan.ProductID} className={`p-4 text-center border-b
                    ${plan.Featured ? 'bg-blue-50' : ''}`}>
                    {plan.features.Websites}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="p-4 font-medium border-b">Almacenamiento</td>
                {plans.map((plan) => (
                  <td key={plan.ProductID} className={`p-4 text-center border-b
                    ${plan.Featured ? 'bg-blue-50' : ''}`}>
                    {plan.Storage}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="p-4 font-medium border-b">Bases de Datos</td>
                {plans.map((plan) => (
                  <td key={plan.ProductID} className={`p-4 text-center border-b
                    ${plan.Featured ? 'bg-blue-50' : ''}`}>
                    {plan.features.Databases}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="p-4 font-medium border-b">Ancho de Banda</td>
                {plans.map((plan) => (
                  <td key={plan.ProductID} className={`p-4 text-center border-b
                    ${plan.Featured ? 'bg-blue-50' : ''}`}>
                    {plan.Bandwidth}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="p-4 font-medium border-b">Dominio Gratis</td>
                {plans.map((plan) => (
                  <td key={plan.ProductID} className={`p-4 text-center border-b
                    ${plan.Featured ? 'bg-blue-50' : ''}`}>
                    <Check className="w-5 h-5 text-green-500 mx-auto" />
                  </td>
                ))}
              </tr>
              <tr>
                <td className="p-4 font-medium border-b">SSL Gratis</td>
                {plans.map((plan) => (
                  <td key={plan.ProductID} className={`p-4 text-center border-b
                    ${plan.Featured ? 'bg-blue-50' : ''}`}>
                    <Check className="w-5 h-5 text-green-500 mx-auto" />
                  </td>
                ))}
              </tr>
              <tr>
                <td className="p-4 font-medium border-b">Cuentas de Correo</td>
                {plans.map((plan) => (
                  <td key={plan.ProductID} className={`p-4 text-center border-b
                    ${plan.Featured ? 'bg-blue-50' : ''}`}>
                    {plan.features.Emails}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="p-4 font-medium border-b">Soporte</td>
                {plans.map((plan) => (
                  <td key={plan.ProductID} className={`p-4 text-center border-b
                    ${plan.Featured ? 'bg-blue-50' : ''}`}>
                    {plan.features.Support}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
