import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { 
  Cpu, 
  CircuitBoard, 
  HardDrive, 
  Network,
  ChevronRight,
  Clock,
  Shield
} from "lucide-react";

export default function Pricing() {
  const [isAnnual, setIsAnnual] = useState(false);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      const targetUrl = 'https://hosting.wecodecr.com/api/products/VDS';
      try {
        const proxyUrl = 'https://corsproxy.io/?';
        const response = await axios.get(proxyUrl + encodeURIComponent(targetUrl));
        setPlans(response.data.data);
        setLoading(false);
      } catch (err) {
        try {
          const backupProxyUrl = 'https://api.allorigins.win/raw?url=';
          const backupResponse = await axios.get(backupProxyUrl + encodeURIComponent(targetUrl));
          setPlans(backupResponse.data.data);
          setLoading(false);
        } catch (backupErr) {
          setError('Error al cargar los planes');
          setLoading(false);
        }
      }
    };
    
    fetchPlans();
  }, []);

  const handlePurchase = (plan) => {
    navigate('/hosting/checkout', { 
      state: { 
        plan,
        billingCycle: isAnnual ? 'annual' : 'monthly'
      } 
    });
  };

  if (loading) return (
    <div className="min-h-screen flex justify-center items-center bg-white">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-black"></div>
    </div>
  );

  return (
    <div className="min-h-screen bg-white">
      {/* Header al estilo Uber */}
      <div className="bg-black text-white py-20">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-5xl font-bold mb-6">
            Servidores Dedicados Virtuales
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl">
            Potencia y rendimiento empresarial con recursos dedicados
          </p>
        </div>
      </div>

      {/* Toggle de facturación estilo Uber */}
      <div className="max-w-7xl mx-auto px-4 -mt-8">
        <div className="bg-white rounded-xl shadow-lg p-2 inline-flex">
          <button
            onClick={() => setIsAnnual(false)}
            className={`px-6 py-3 rounded-lg transition-all ${
              !isAnnual 
                ? 'bg-black text-white' 
                : 'text-gray-500 hover:text-black'
            }`}
          >
            Mensual
          </button>
          <button
            onClick={() => setIsAnnual(true)}
            className={`px-6 py-3 rounded-lg transition-all ${
              isAnnual 
                ? 'bg-black text-white' 
                : 'text-gray-500 hover:text-black'
            }`}
          >
            Anual
            {isAnnual && (
              <span className="ml-2 text-xs bg-green-500 px-2 py-1 rounded-full">
                20% OFF
              </span>
            )}
          </button>
        </div>
      </div>

      {/* Grid de planes estilo Uber */}
      <div className="max-w-[90rem] mx-auto px-4 py-16">
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
          {plans.map((vds) => (
            <div
              key={vds.ProductID}
              className={`group rounded-2xl transition-all duration-300 hover:shadow-2xl
                ${vds.Featured 
                  ? 'lg:col-span-1 ring-2 ring-black scale-105 shadow-xl' 
                  : 'border border-gray-200'}`}
            >
              <div className="p-6">
                {vds.Featured && (
                  <div className="inline-block bg-black text-white text-sm px-3 py-1 rounded-full mb-4">
                    Recomendado
                  </div>
                )}

                <h3 className="text-xl font-bold mb-2">{vds.Title}</h3>
                <p className="text-gray-600 mb-4 text-sm">{vds.Description}</p>

                <div className="mb-6">
                  <div className="text-3xl font-bold">
                    ${isAnnual ? (vds.BasePrice * 12 * 0.8).toFixed(2) : vds.BasePrice.toFixed(2)}
                    <span className="text-base font-normal text-gray-500">
                      /{isAnnual ? 'año' : 'mes'}
                    </span>
                  </div>
                  {isAnnual && (
                    <div className="text-green-600 text-xs mt-1">
                      Ahorras 20% con facturación anual
                    </div>
                  )}
                </div>

                <div className="space-y-3 mb-6">
                  {[
                    { icon: Cpu, text: vds.CPU },
                    { icon: CircuitBoard, text: vds.RAM },
                    { icon: HardDrive, text: vds.Storage },
                    { icon: Network, text: vds.Bandwidth }
                  ].map((feature, index) => (
                    <div key={index} className="flex items-center gap-2 text-sm">
                      <feature.icon className="w-4 h-4 text-gray-400" />
                      <span className="text-gray-600">{feature.text}</span>
                    </div>
                  ))}
                </div>

                <button
                  onClick={() => handlePurchase(vds)}
                  className="w-full bg-black text-white py-3 rounded-xl text-sm font-medium
                    hover:bg-gray-900 transition-colors flex items-center justify-center gap-2"
                >
                  Seleccionar
                  <ChevronRight className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Características adicionales estilo Uber */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12 text-center">
            Por qué elegir nuestros VDS
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="text-center">
              <div className="w-12 h-12 bg-black rounded-full flex items-center justify-center mx-auto mb-6">
                <Shield className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-xl font-semibold mb-3">Seguridad Enterprise</h3>
              <p className="text-gray-600">
                Protección DDoS y firewall dedicado incluidos en todos los planes
              </p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-black rounded-full flex items-center justify-center mx-auto mb-6">
                <Cpu className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-xl font-semibold mb-3">Hardware Premium</h3>
              <p className="text-gray-600">
                Procesadores Intel Xeon y discos NVMe de última generación
              </p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-black rounded-full flex items-center justify-center mx-auto mb-6">
                <Clock className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-xl font-semibold mb-3">Soporte 24/7</h3>
              <p className="text-gray-600">
                Equipo técnico especializado disponible en todo momento
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
