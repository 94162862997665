import React from "react";
import { useTranslation } from "react-i18next";
import { Building2, ShoppingCart, Briefcase, Target } from "lucide-react";

export default function Examples() {
  const { t } = useTranslation();

  const examples = [
    {
      icon: Building2,
      title: t("promo.examples.corporate.title"),
      description: t("promo.examples.corporate.description"),
      features: t("promo.examples.corporate.features", { returnObjects: true })
    },
    {
      icon: ShoppingCart,
      title: t("promo.examples.store.title"),
      description: t("promo.examples.store.description"),
      features: t("promo.examples.store.features", { returnObjects: true })
    },
    {
      icon: Briefcase,
      title: t("promo.examples.services.title"),
      description: t("promo.examples.services.description"),
      features: t("promo.examples.services.features", { returnObjects: true })
    },
    {
      icon: Target,
      title: t("promo.examples.landing.title"),
      description: t("promo.examples.landing.description"),
      features: t("promo.examples.landing.features", { returnObjects: true })
    }
  ];

  return (
    <section className="py-16 bg-gray-900">
      <div className="container px-4 mx-auto">
        <div className="max-w-2xl mx-auto text-center mb-16">
          <h2 className="text-3xl font-bold text-white mb-4">
            {t("promo.examples.title")}
          </h2>
          <p className="text-gray-400">
            {t("promo.examples.subtitle")}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {examples.map((example, index) => {
            const Icon = example.icon;
            return (
              <div key={index} className="bg-gray-800 rounded-lg p-6 hover:bg-gray-700 transition-colors duration-300">
                <div className="w-12 h-12 bg-blue-600 rounded-lg flex items-center justify-center mb-4">
                  <Icon className="w-6 h-6 text-white" />
                </div>
                
                <h3 className="text-xl font-semibold text-white mb-3">
                  {example.title}
                </h3>
                
                <p className="text-gray-400 mb-4">
                  {example.description}
                </p>
                
                <ul className="space-y-2">
                  {example.features.map((feature, fIndex) => (
                    <li key={fIndex} className="flex items-center text-gray-300">
                      <span className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>

        <div className="text-center mt-12">
          <a
            href="https://api.whatsapp.com/send?phone=50670602186&text=Hola,%20me%20interesa%20la%20oferta%20del%20sitio%20web%20por%20$50%20mensuales.%20¿Me%20pueden%20dar%20más%20información?"
            className="inline-flex items-center px-6 py-3 text-lg font-semibold rounded-lg bg-[#00acee] text-white hover:bg-blue-700 transition-colors duration-300"
          >
            {t("promo.ctaButton")}
          </a>
        </div>
      </div>
    </section>
  );
} 