import React from "react";
import { Nav } from "../../about/navbar";
import Footer from "../../home/footer";
import { useTranslation } from "react-i18next";

export default function Refund() {
  const { t } = useTranslation();

  return (
    <>
      <div className="relative bg-gray-900">
        <Nav />
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-white"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
            <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
              {t("refund.title")}
            </h2>
            <p className="mb-6 text-base font-medium tracking-wide text-gray-300 md:text-lg">
              {t("refund.subtitle")}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Conditions Section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              {t("refund.conditionsTitle")}
            </h3>
            <p className="text-gray-600 mb-4">
              {t("refund.conditionsDescription")}
            </p>
            <ul className="list-disc pl-5 space-y-2">
              {t("refund.conditions", { returnObjects: true }).map((condition, index) => (
                <li key={index} className="text-gray-600">{condition}</li>
              ))}
            </ul>
          </div>

          {/* Timeframes Section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              {t("refund.timeTitle")}
            </h3>
            <p className="text-gray-600 mb-4">
              {t("refund.timeDescription")}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {t("refund.times", { returnObjects: true }).map((time, index) => (
                <div key={index} className="border p-4 rounded-lg">
                  <h4 className="font-semibold text-gray-900">{time.service}</h4>
                  <p className="text-gray-600">{time.period}</p>
                  <p className="text-gray-500 text-sm mt-2">{time.details}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Changes Section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              {t("refund.changesTitle")}
            </h3>
            <p className="text-gray-600 mb-4">
              {t("refund.changesDescription")}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {t("refund.changes", { returnObjects: true }).map((change, index) => (
                <div key={index} className="border p-4 rounded-lg">
                  <h4 className="font-semibold text-gray-900">{change.type}</h4>
                  <p className="text-gray-600 text-sm mt-2">{change.description}</p>
                  <p className="text-gray-500 text-sm mt-2">{change.conditions}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Exclusions Section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              {t("refund.exclusionsTitle")}
            </h3>
            <ul className="list-disc pl-5 space-y-2">
              {t("refund.exclusions", { returnObjects: true }).map((exclusion, index) => (
                <li key={index} className="text-gray-600">{exclusion}</li>
              ))}
            </ul>
          </div>

          {/* Procedure Section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              {t("refund.procedureTitle")}
            </h3>
            <p className="text-gray-600 mb-4">
              {t("refund.procedureDescription")}
            </p>
            <div className="bg-gray-50 p-6 rounded-lg">
              {t("refund.procedures", { returnObjects: true }).map((procedure, index) => (
                <p key={index} className="text-gray-600 mb-2">{procedure}</p>
              ))}
            </div>
          </div>

          <div className="text-gray-600 italic mb-12">
            {t("refund.note")}
          </div>

          {/* Contact Section */}
          <div className="bg-blue-50 p-6 rounded-lg">
            <h3 className="text-xl font-bold text-gray-900 mb-2">
              {t("refund.contact.title")}
            </h3>
            <p className="text-gray-600 mb-4">
              {t("refund.contact.description")}
            </p>
            <div className="space-y-2">
              <p className="text-gray-600">
                Email: {t("refund.contact.email")}
              </p>
              <p className="text-gray-600">
                Tel: {t("refund.contact.phone")}
              </p>
              <p className="text-gray-600">
                {t("refund.contact.support")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
} 