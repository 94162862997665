import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { 
  Cpu, 
  CircuitBoard,
  HardDrive, 
  Network,
  ChevronRight,
  Server,
  Shield
} from "lucide-react";

export default function Pricing() {
  const [isAnnual, setIsAnnual] = useState(false);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      const targetUrl = 'https://hosting.wecodecr.com/api/products/DEDICATED';
      const proxies = [
        'https://corsproxy.io/?',
        'https://api.allorigins.win/raw?url=',
        'https://api.codetabs.com/v1/proxy?quest=',
        'https://cors-anywhere.herokuapp.com/',
        'https://proxy.cors.sh/'
      ];

      for (const proxy of proxies) {
        try {
          const response = await axios.get(proxy + encodeURIComponent(targetUrl), {
            timeout: 5000, // 5 segundos timeout
            headers: {
              'x-requested-with': 'XMLHttpRequest'
            }
          });
          
          if (response.data && response.data.data) {
            setPlans(response.data.data);
            setLoading(false);
            return;
          }
        } catch (err) {
          console.log(`Error con proxy ${proxy}:`, err);
          continue;
        }
      }

      // Si llegamos aquí, todos los proxies fallaron
      setError('Error al cargar los planes. Por favor, intente más tarde.');
      setLoading(false);
    };
    
    fetchPlans();
  }, []);

  const handlePurchase = (plan) => {
    navigate('/hosting/checkout', { 
      state: { 
        plan,
        billingCycle: isAnnual ? 'annual' : 'monthly'
      } 
    });
  };

  if (loading) return (
    <div className="min-h-screen flex justify-center items-center bg-white">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-black"></div>
    </div>
  );

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <div className="bg-gradient-to-r from-gray-900 to-black text-white py-20">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-5xl font-bold mb-6">
            Servidores Dedicados
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl">
            Máximo rendimiento con hardware exclusivo y recursos dedicados
          </p>
        </div>
      </div>

      {/* Toggle de facturación */}
      <div className="max-w-7xl mx-auto px-4 -mt-8">
        <div className="bg-white rounded-xl shadow-lg p-2 inline-flex">
          <button
            onClick={() => setIsAnnual(false)}
            className={`px-6 py-3 rounded-lg transition-all ${
              !isAnnual 
                ? 'bg-black text-white' 
                : 'text-gray-500 hover:text-black'
            }`}
          >
            Mensual
          </button>
          <button
            onClick={() => setIsAnnual(true)}
            className={`px-6 py-3 rounded-lg transition-all ${
              isAnnual 
                ? 'bg-black text-white' 
                : 'text-gray-500 hover:text-black'
            }`}
          >
            Anual
            {isAnnual && (
              <span className="ml-2 text-xs bg-green-500 px-2 py-1 rounded-full">
                20% OFF
              </span>
            )}
          </button>
        </div>
      </div>

      {/* Grid de planes */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {plans.map((server) => (
            <div
              key={server.ProductID}
              className={`rounded-2xl transition-all duration-300 hover:shadow-2xl
                ${server.Featured ? 'ring-2 ring-black scale-105' : 'border border-gray-200'}`}
            >
              <div className="p-8">
                {server.Featured && (
                  <div className="inline-block bg-black text-white text-sm px-3 py-1 rounded-full mb-4">
                    Recomendado
                  </div>
                )}

                <h3 className="text-2xl font-bold mb-2">{server.Title}</h3>
                <p className="text-gray-600 mb-6">{server.Description}</p>

                <div className="mb-8">
                  <div className="text-4xl font-bold">
                    ${isAnnual ? (server.BasePrice * 12 * 0.8).toFixed(2) : server.BasePrice}
                    <span className="text-lg font-normal text-gray-500">
                      /{isAnnual ? 'año' : 'mes'}
                    </span>
                  </div>
                  {isAnnual && (
                    <div className="text-green-600 text-sm mt-1">
                      Ahorras 20% con facturación anual
                    </div>
                  )}
                </div>

                <div className="space-y-4 mb-8">
                  <div className="flex items-center gap-3">
                    <Cpu className="w-5 h-5 text-gray-400" />
                    <span className="text-gray-600">{server.CPU}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <CircuitBoard className="w-5 h-5 text-gray-400" />
                    <span className="text-gray-600">{server.RAM}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <HardDrive className="w-5 h-5 text-gray-400" />
                    <span className="text-gray-600">{server.Storage}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <Network className="w-5 h-5 text-gray-400" />
                    <span className="text-gray-600">{server.Bandwidth}</span>
                  </div>
                </div>

                <button
                  onClick={() => handlePurchase(server)}
                  className="w-full bg-black text-white py-4 rounded-xl font-medium
                    hover:bg-gray-900 transition-colors flex items-center justify-center gap-2"
                >
                  Configurar Servidor
                  <ChevronRight className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Características adicionales */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12 text-center">
            Características Premium
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="text-center">
              <div className="w-12 h-12 bg-black rounded-full flex items-center justify-center mx-auto mb-6">
                <Server className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-xl font-semibold mb-3">Hardware Enterprise</h3>
              <p className="text-gray-600">
                Servidores de última generación con componentes premium
              </p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-black rounded-full flex items-center justify-center mx-auto mb-6">
                <Shield className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-xl font-semibold mb-3">Seguridad Avanzada</h3>
              <p className="text-gray-600">
                Protección DDoS y firewall dedicado incluidos
              </p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-black rounded-full flex items-center justify-center mx-auto mb-6">
                <Network className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-xl font-semibold mb-3">Red Global</h3>
              <p className="text-gray-600">
                Múltiples ubicaciones con conexión de alta velocidad
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
