// careers.js
import React, { useState, useEffect } from "react";
import { Nav } from "../navbar";
import SearchBar from "./search.tsx";
import Cards from "./cards.js";
import jobOffersData from "./jobOffers.json";
import Value from './value.js'
import CTA from './cta.js'
import Footer from '../../home/footer.js'
import { useTranslation } from "react-i18next";
import { Briefcase, ChevronDown, Globe2, Rocket, ArrowDown } from "lucide-react";

import "../../../translations/i18n";

export default function Careers() {
  const [jobOffers, setJobOffers] = useState([]);
  const [filteredJobOffers, setFilteredJobOffers] = useState([]);
  const [isStatsVisible, setIsStatsVisible] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setJobOffers(jobOffersData);
    setFilteredJobOffers(jobOffersData);

    // Animación para las estadísticas
    const handleScroll = () => {
      const statsSection = document.getElementById('stats-section');
      if (statsSection) {
        const rect = statsSection.getBoundingClientRect();
        if (rect.top <= window.innerHeight * 0.75) {
          setIsStatsVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleFilter = (location, jobType, searchInput) => {
    console.log('Filtering with:', { location, jobType, searchInput }); // Para debugging

    let filteredOffers = jobOffers.filter((offer) => {
      // Debug log para ver los valores que estamos comparando
      console.log('Comparing with offer:', {
        offerLocation: offer.location,
        offerJobType: offer.jobType,
        location: location,
        jobType: jobType
      });

      // Función helper para normalizar strings
      const normalizeStr = (str) => {
        if (!str) return '';
        return str.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim();
      };

      // Comparaciones normalizadas
      const locationMatch = !location || normalizeStr(offer.location) === normalizeStr(location);
      const jobTypeMatch = !jobType || normalizeStr(offer.jobType) === normalizeStr(jobType);
      const titleMatch = !searchInput || normalizeStr(offer.title).includes(normalizeStr(searchInput));
      const descriptionMatch = !searchInput || normalizeStr(offer.description).includes(normalizeStr(searchInput));

      // Debug log para ver los resultados de las comparaciones
      console.log('Match results:', {
        locationMatch,
        jobTypeMatch,
        titleMatch,
        descriptionMatch
      });

      return locationMatch && jobTypeMatch && (titleMatch || descriptionMatch);
    });

    console.log('Filtered results:', filteredOffers); // Para ver los resultados finales
    setFilteredJobOffers(filteredOffers);
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900">
        <Nav />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            {/* Hero Content */}
            <div className="text-left">
              <h1 className="text-4xl font-bold text-white sm:text-5xl lg:text-6xl tracking-tight">
                {t("careers.heroTitle")}
              </h1>
              <p className="mt-6 text-xl text-blue-100 max-w-2xl">
                {t("careers.heroSubtitle")}
              </p>
              <p className="mt-4 text-lg text-blue-200">
                {t("careers.subtitle")}
              </p>
              <div className="mt-8 flex flex-wrap gap-4">
                <a
                  href="#jobs"
                  className="inline-flex items-center px-6 py-3 border border-transparent 
                           text-base font-medium rounded-lg text-blue-900 bg-white 
                           hover:bg-blue-50 transition-colors duration-200"
                >
                  {t("careers.ctaButton")}
                  <ArrowDown className="ml-2 h-5 w-5" />
                </a>
              </div>
            </div>

            {/* Stats Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 lg:gap-8">
              {[
                {
                  icon: Briefcase,
                  stat: jobOffers.length,
                  label: t("careers.heroStats.openPositions")
                },
                {
                  icon: Globe2,
                  stat: "15+",
                  label: t("careers.heroStats.countries")
                },
                {
                  icon: Rocket,
                  stat: "100%",
                  label: t("careers.heroStats.remote")
                }
              ].map((item, index) => (
                <div
                  key={index}
                  className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-6 
                           border border-white border-opacity-20"
                >
                  <div className="flex items-center justify-center w-12 h-12 
                                bg-blue-700 bg-opacity-50 rounded-lg mb-4">
                    <item.icon className="h-6 w-6 text-blue-200" />
                  </div>
                  <div className="text-3xl font-bold text-white mb-1">
                    {item.stat}
                  </div>
                  <div className="text-sm text-blue-200">
                    {item.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Search and Jobs Section */}
      <div className="bg-white dark:bg-gray-900">
        <div className="relative -mt-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl">
            <SearchBar handleFilter={handleFilter} />
          </div>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {filteredJobOffers.length === 0 ? (
            <div className="text-center py-12">
              <div className="inline-block p-8 bg-gray-50 dark:bg-gray-800 rounded-lg">
                <Briefcase className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-4 text-lg font-medium text-gray-900 dark:text-white">
                  {t("careers.noJobsFound")}
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  Intenta ajustar tus filtros o busca con otros términos
                </p>
              </div>
            </div>
          ) : (
            <Cards jobOffers={filteredJobOffers} />
          )}
        </div>
      </div>

      {/* Benefits and Values Section */}
      <Value />

      {/* Call to Action Section */}
      <CTA />

      {/* Footer */}
      <Footer />
    </div>
  );
}
