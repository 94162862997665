import React, { useState } from "react";
import { 
  Calendar, MapPin, Briefcase, DollarSign, 
  ChevronRight, X, Clock, Globe, CheckCircle, 
  Mail, Download 
} from "lucide-react";
import { useTranslation } from "react-i18next";

export default function Cards({ jobOffers }) {
  const [selectedJob, setSelectedJob] = useState(null);
  const { t } = useTranslation();

  const openModal = (job) => {
    setSelectedJob(job);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setSelectedJob(null);
    document.body.style.overflow = 'unset';
  };

  return (
    <div id="jobs" className="space-y-8">
      {/* Job Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {jobOffers.map((job, index) => (
          <div
            key={index}
            className="group bg-white dark:bg-gray-800 rounded-xl shadow-md hover:shadow-xl 
                     transition-all duration-300 overflow-hidden border border-gray-100 
                     dark:border-gray-700 flex flex-col"
          >
            {/* Card Header */}
            <div className="p-6 flex-grow">
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <h3 className="text-xl font-bold text-gray-900 dark:text-white 
                               group-hover:text-blue-600 dark:group-hover:text-blue-400 
                               transition-colors duration-200">
                    {job.title}
                  </h3>
                  <p className="mt-2 text-gray-600 dark:text-gray-300 line-clamp-2">
                    {job.description}
                  </p>
                </div>
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm 
                               font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 
                               dark:text-blue-200">
                  {job.jobType}
                </span>
              </div>

              {/* Job Details */}
              <div className="mt-4 space-y-3">
                <div className="flex items-center text-gray-500 dark:text-gray-400">
                  <MapPin className="w-4 h-4 mr-2" />
                  <span className="text-sm">{job.location}</span>
                </div>
                <div className="flex items-center text-gray-500 dark:text-gray-400">
                  <DollarSign className="w-4 h-4 mr-2" />
                  <span className="text-sm">{job.salary}</span>
                </div>
                <div className="flex items-center text-gray-500 dark:text-gray-400">
                  <Calendar className="w-4 h-4 mr-2" />
                  <span className="text-sm">{job.datePosted}</span>
                </div>
              </div>
            </div>

            {/* Card Footer */}
            <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700/50 border-t 
                          border-gray-100 dark:border-gray-700">
              <div className="flex justify-between items-center">
                <button
                  onClick={() => openModal(job)}
                  className="text-blue-600 dark:text-blue-400 hover:text-blue-700 
                           dark:hover:text-blue-300 font-medium flex items-center"
                >
                  {t("careers.viewDetails")}
                  <ChevronRight className="w-4 h-4 ml-1" />
                </button>
                <button
                  onClick={() => window.location.href = `mailto:jobs@wecodecr.com?subject=Application for ${job.title}`}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                           transition-colors duration-200 flex items-center"
                >
                  {t("careers.applyNow")}
                  <Mail className="w-4 h-4 ml-2" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Job Details Modal */}
      {selectedJob && (
        <div className="fixed inset-0 z-50 overflow-y-auto" onClick={closeModal}>
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20">
            <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 
                          dark:bg-opacity-75 transition-opacity" />

            <div className="relative bg-white dark:bg-gray-800 rounded-xl max-w-4xl w-full 
                          mx-auto shadow-2xl overflow-hidden"
                 onClick={e => e.stopPropagation()}>
              {/* Modal Header */}
              <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700 border-b 
                            border-gray-200 dark:border-gray-600 flex justify-between items-start">
                <div>
                  <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                    {selectedJob.title}
                  </h2>
                  <div className="mt-2 flex flex-wrap gap-3">
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm 
                                   font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 
                                   dark:text-blue-200">
                      <Clock className="w-4 h-4 mr-1" />
                      {selectedJob.jobType}
                    </span>
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm 
                                   font-medium bg-green-100 text-green-800 dark:bg-green-900 
                                   dark:text-green-200">
                      <Globe className="w-4 h-4 mr-1" />
                      {selectedJob.location}
                    </span>
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm 
                                   font-medium bg-purple-100 text-purple-800 dark:bg-purple-900 
                                   dark:text-purple-200">
                      <DollarSign className="w-4 h-4 mr-1" />
                      {selectedJob.salary}
                    </span>
                  </div>
                </div>
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>

              {/* Modal Content */}
              <div className="px-6 py-6 max-h-[calc(100vh-200px)] overflow-y-auto">
                <div className="space-y-8">
                  {/* Description Section */}
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                      {t("careers.jobDescription")}
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300 whitespace-pre-line">
                      {selectedJob.fullDescription}
                    </p>
                  </div>

                  {/* Requirements Section */}
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                      {t("careers.requirements")}
                    </h3>
                    <ul className="space-y-3">
                      {selectedJob.requirements.map((req, index) => (
                        <li key={index} className="flex items-start">
                          <CheckCircle className="w-5 h-5 text-green-500 mr-3 mt-0.5" />
                          <span className="text-gray-600 dark:text-gray-300">{req}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              {/* Modal Footer */}
              <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t 
                            border-gray-200 dark:border-gray-600 flex justify-end">
                <button
                  onClick={closeModal}
                  className="px-4 py-2 border border-gray-300 dark:border-gray-600 
                           text-gray-700 dark:text-gray-300 rounded-lg mr-3 
                           hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  {t("careers.close")}
                </button>
                <a
                  href={`mailto:jobs@wecodecr.com?subject=Application for ${selectedJob.title}`}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg 
                           hover:bg-blue-700 transition-colors duration-200"
                >
                  {t("careers.applyNow")}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
