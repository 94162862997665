import React from "react";
import { Nav } from "../../about/navbar";
import Footer from "../../home/footer";
import { useTranslation } from "react-i18next";
import { Shield, Lock, Eye, Key } from "lucide-react";

export default function Privacy() {
  const { t } = useTranslation();

  return (
    <>
      <div className="relative bg-gray-900">
        <Nav />
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-white"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
            <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
              {t("privacy.title")}
            </h2>
            <p className="mb-6 text-base font-medium tracking-wide text-gray-300 md:text-lg">
              {t("privacy.subtitle")}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Data Usage Section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              {t("privacy.dataUsageTitle")}
            </h3>
            <p className="text-gray-600 mb-4">
              {t("privacy.dataUsageDescription")}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {t("privacy.dataUsage", { returnObjects: true }).map((usage, index) => (
                <div key={index} className="border p-4 rounded-lg">
                  <h4 className="font-semibold text-gray-900">{usage.type}</h4>
                  <ul className="list-disc pl-5 mt-2">
                    {usage.data.map((item, i) => (
                      <li key={i} className="text-gray-600 text-sm">{item}</li>
                    ))}
                  </ul>
                  <p className="text-gray-500 text-sm mt-2">{usage.purpose}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Security Section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              {t("privacy.securityTitle")}
            </h3>
            <p className="text-gray-600 mb-4">
              {t("privacy.securityDescription")}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {t("privacy.security", { returnObjects: true }).map((item, index) => (
                <div key={index} className="border p-4 rounded-lg">
                  <div className="flex items-center mb-2">
                    {index === 0 && <Lock className="w-5 h-5 mr-2 text-green-500" />}
                    {index === 1 && <Shield className="w-5 h-5 mr-2 text-blue-500" />}
                    {index === 2 && <Key className="w-5 h-5 mr-2 text-purple-500" />}
                    <h4 className="font-semibold text-gray-900">{item.title}</h4>
                  </div>
                  <p className="text-gray-600 text-sm">{item.description}</p>
                  <p className="text-gray-500 text-sm mt-2">{item.details || item.verification}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Transactions Section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              {t("privacy.transactionsTitle")}
            </h3>
            <p className="text-gray-600 mb-4">
              {t("privacy.transactionsDescription")}
            </p>
            <div className="bg-gray-50 p-6 rounded-lg">
              {t("privacy.transactions", { returnObjects: true }).map((transaction, index) => (
                <div key={index} className="flex items-start mb-3">
                  <Eye className="w-5 h-5 mr-2 text-blue-500 mt-1" />
                  <p className="text-gray-600">{transaction}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Protection Measures Section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              {t("privacy.protectionTitle")}
            </h3>
            <p className="text-gray-600 mb-4">
              {t("privacy.protectionDescription")}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {t("privacy.protection", { returnObjects: true }).map((protection, index) => (
                <div key={index} className="border p-4 rounded-lg">
                  <h4 className="font-semibold text-gray-900">{protection.measure}</h4>
                  <p className="text-gray-600 text-sm mt-2">{protection.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Data Rights Section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              {t("privacy.dataRightsTitle")}
            </h3>
            <ul className="list-disc pl-5 space-y-2">
              {t("privacy.dataRights", { returnObjects: true }).map((right, index) => (
                <li key={index} className="text-gray-600">{right}</li>
              ))}
            </ul>
          </div>

          {/* Verification Steps Section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              {t("privacy.verificationTitle")}
            </h3>
            <div className="bg-blue-50 p-6 rounded-lg">
              {t("privacy.verificationSteps", { returnObjects: true }).map((step, index) => (
                <div key={index} className="flex items-start mb-3">
                  <Shield className="w-5 h-5 mr-2 text-blue-500 mt-1" />
                  <p className="text-gray-600">{step}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Contact Section */}
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-xl font-bold text-gray-900 mb-2">
              {t("privacy.contact.title")}
            </h3>
            <p className="text-gray-600 mb-4">
              {t("privacy.contact.description")}
            </p>
            <div className="space-y-2">
              <p className="text-gray-600">
                Email: {t("privacy.contact.email")}
              </p>
              <p className="text-gray-600">
                Tel: {t("privacy.contact.phone")}
              </p>
              <p className="text-gray-600">
                {t("privacy.contact.support")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
} 