import React, { lazy, Suspense, useEffect } from "react";
import { Nav } from "./navbar";
import Hero from "./hero";
// import Nexus from "./nexus";
// import Tech from "./tech";
import Features from "./features";
import CTAClient from "./cta-clients";
import Projects from "./projects";
import Testimonials from "./testimonials";
import Faq from "./faq";
import Footer from "./footer";
// import Banner from "../utils/announcement-banners";

export const Main = () => {
  useEffect(() => {
    const handleScrollLock = () => {
      if (window.innerWidth < 768) {
        // Si el ancho de la ventana es menor que 768px (dispositivo móvil)
        document.body.style.overflowX = "hidden"; // Bloquear desplazamiento horizontal
      } else {
        document.body.style.overflowX = "auto"; // Permitir desplazamiento horizontal
      }
    };

    // Agregar evento de escucha para cambiar el desplazamiento en función del ancho de la ventana
    window.addEventListener("resize", handleScrollLock);

    // Limpieza: eliminar el evento de escucha al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleScrollLock);
    };
  }, []); // El efecto solo se ejecuta una vez al montar el componente

  return (
    <div className="dark:bg-gray-900">
      <Suspense fallback={<div>Loading...</div>}>
        {/* <Banner /> */}
      </Suspense>
      <Nav />
      <Hero />
      {/* <Parallaxdown /> */}

      {/* Utiliza Suspense para manejar la carga diferida */}
      <Suspense fallback={<div>Loading...</div>}>
        <Features />
        <CTAClient />
        {/* <Tech /> */}
        {/* <Nexus /> */}
        <Projects />
        <Testimonials />
        <Faq />
        <Footer />
      </Suspense>
    </div>
  );
};
