import React from "react";
import { useTranslation } from "react-i18next";
import { 
  Rocket, Clock, GraduationCap, Lightbulb, 
  Users, CheckCircle, Target, Zap 
} from "lucide-react";

export default function Value() {
  const { t } = useTranslation();

  return (
    <div className="py-16 bg-gray-50 dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Why Join WeCode Section */}
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            {t("careers.whyJoinTitle")}
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            {t("careers.whyJoinSubtitle")}
          </p>
          
          <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { icon: Users, key: 'collaboration' },
              { icon: Rocket, key: 'innovation' },
              { icon: Zap, key: 'technology' },
              { icon: Target, key: 'growth' }
            ].map((item, index) => (
              <div key={index} className="p-6 bg-white dark:bg-gray-700 rounded-xl shadow-md">
                <div className="w-12 h-12 bg-blue-100 dark:bg-blue-900 rounded-full 
                              flex items-center justify-center mx-auto mb-4">
                  <item.icon className="w-6 h-6 text-blue-600 dark:text-blue-300" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {t(`careers.whyJoinReasons.${item.key}`)}
                </h3>
              </div>
            ))}
          </div>
        </div>

        {/* Stats Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {['professionals', 'projects', 'countries'].map((stat, index) => (
            <div key={index} className="bg-white dark:bg-gray-700 rounded-xl p-6 text-center">
              <div className="text-4xl font-bold text-blue-600 dark:text-blue-400 mb-2">
                {t(`careers.stats.${stat}.number`)}
              </div>
              <div className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                {t(`careers.stats.${stat}.title`)}
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                {t(`careers.stats.${stat}.description`)}
              </p>
            </div>
          ))}
        </div>

        {/* Culture Section */}
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            {t("careers.cultureTitle")}
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto mb-12">
            {t("careers.cultureDescription")}
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {['innovation', 'collaboration', 'excellence'].map((value, index) => (
              <div key={index} className="bg-white dark:bg-gray-700 rounded-xl p-6 shadow-md">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                  {t(`careers.cultureValues.${value}.title`)}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  {t(`careers.cultureValues.${value}.description`)}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Selection Process */}
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-12">
            {t("careers.selectionProcess.title")}
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[0, 1, 2, 3].map((index) => (
              <div key={index} className="relative">
                <div className="bg-white dark:bg-gray-700 rounded-xl p-6 shadow-md">
                  <div className="w-10 h-10 bg-blue-600 text-white rounded-full 
                                flex items-center justify-center mx-auto mb-4 text-xl font-bold">
                    {t(`careers.selectionProcess.steps.${index}.step`)}
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                    {t(`careers.selectionProcess.steps.${index}.title`)}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    {t(`careers.selectionProcess.steps.${index}.description`)}
                  </p>
                </div>
                {index < 3 && (
                  <div className="hidden lg:block absolute top-1/2 -right-4 w-8 h-0.5 bg-blue-600" />
                )}
              </div>
            ))}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-12">
            {t("careers.faq.title")}
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[0, 1, 2].map((index) => (
              <div key={index} className="bg-white dark:bg-gray-700 rounded-xl p-6 shadow-md">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-3">
                  {t(`careers.faq.questions.${index}.q`)}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  {t(`careers.faq.questions.${index}.a`)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
