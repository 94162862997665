import React from "react";
import { Nav } from "./navbar.js";
import { useTranslation } from "react-i18next";
import { Globe, Shield, Server, Mail } from "lucide-react";

export default function Hero() {
  const { t } = useTranslation();
  return (
    <div className="bg-slate-900">
      <div className="bg-gradient-to-b from-violet-600/[.15] via-transparent">
        <Nav />
        <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-24 space-y-8">
          <div className="max-w-3xl text-center mx-auto">
            <h1 className="block font-bold text-gray-200 text-4xl sm:text-5xl md:text-6xl lg:text-7xl">
              {t("promo.title")}
            </h1>
          </div>

          <div className="max-w-3xl text-center mx-auto">
            <p className="text-2xl text-slate-100">
              {t("promo.subtitle")}{" "}
              <span className="font-bold text-[#00acee]">
                {t("promo.subtitle2")}
              </span>{" "}
              <span className="text-[#00acee] font-bold">
                {t("promo.subtitle3")}
              </span>
            </p>
          </div>

          {/* Call to Action Button */}
          <div className="text-center">
            <a
              href="https://api.whatsapp.com/send?phone=50670602186&text=Hola,%20me%20interesa%20la%20oferta%20del%20sitio%20web%20por%20$50%20mensuales.%20¿Me%20pueden%20dar%20más%20información?"
              className="inline-flex items-center gap-x-2 px-6 py-3 text-lg font-semibold rounded-lg border border-transparent bg-[#00acee] text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none transition-all duration-300"
            >
              {t("promo.ctaButton")}
              <svg
                className="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </a>
          </div>

          {/* Badges Section */}
          <div className="mt-8 grid gap-4 grid-cols-2 sm:grid-cols-4">
            <div className="text-center p-4 bg-gray-800/50 rounded-lg backdrop-blur-sm">
              <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-blue-100 dark:bg-blue-900">
                <Globe className="w-6 h-6 text-blue-600 dark:text-blue-300" />
              </div>
              <h3 className="text-lg font-semibold text-gray-200">
                {t("promo.badges.website")}
              </h3>
            </div>

            <div className="text-center p-4 bg-gray-800/50 rounded-lg backdrop-blur-sm">
              <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-blue-100 dark:bg-blue-900">
                <Server className="w-6 h-6 text-blue-600 dark:text-blue-300" />
              </div>
              <h3 className="text-lg font-semibold text-gray-200">
                {t("promo.badges.hosting")}
              </h3>
            </div>

            <div className="text-center p-4 bg-gray-800/50 rounded-lg backdrop-blur-sm">
              <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-blue-100 dark:bg-blue-900">
                <Shield className="w-6 h-6 text-blue-600 dark:text-blue-300" />
              </div>
              <h3 className="text-lg font-semibold text-gray-200">
                {t("promo.badges.maintenance")}
              </h3>
            </div>

            <div className="text-center p-4 bg-gray-800/50 rounded-lg backdrop-blur-sm">
              <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-blue-100 dark:bg-blue-900">
                <Mail className="w-6 h-6 text-blue-600 dark:text-blue-300" />
              </div>
              <h3 className="text-lg font-semibold text-gray-200">
                {t("promo.badges.email")}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

