import React from "react";
import { useTranslation } from "react-i18next";
import { ArrowRight, Briefcase, Users, Globe } from "lucide-react";

export default function CTA() {
  const { t } = useTranslation();

  return (
    <div className="py-16 bg-white dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Main CTA Section */}
        <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-2xl overflow-hidden">
          <div className="px-6 py-12 md:px-12 md:py-16 lg:py-20 relative">
            {/* Background Pattern */}
            <div className="absolute inset-0 opacity-10">
              <svg className="w-full h-full" viewBox="0 0 80 80" fill="none">
                <path d="M14 16H10V28H14V16Z" fill="currentColor" />
                <path d="M34 16H30V28H34V16Z" fill="currentColor" />
                <path d="M54 16H50V28H54V16Z" fill="currentColor" />
                <path d="M74 16H70V28H74V16Z" fill="currentColor" />
                <path d="M14 36H10V48H14V36Z" fill="currentColor" />
                <path d="M34 36H30V48H34V36Z" fill="currentColor" />
                <path d="M54 36H50V48H54V36Z" fill="currentColor" />
                <path d="M74 36H70V48H74V36Z" fill="currentColor" />
                <path d="M14 56H10V68H14V56Z" fill="currentColor" />
                <path d="M34 56H30V68H34V56Z" fill="currentColor" />
                <path d="M54 56H50V68H54V56Z" fill="currentColor" />
                <path d="M74 56H70V68H74V56Z" fill="currentColor" />
              </svg>
            </div>

            <div className="relative">
              {/* CTA Content */}
              <div className="text-center max-w-3xl mx-auto">
                <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                  {t("careers.ctaTitle")}
                </h2>
                <p className="text-xl text-blue-100 mb-8">
                  {t("careers.ctaSubtitle")}
                </p>
                <div className="flex flex-wrap justify-center gap-4 mb-12">
                  <a
                    href="#jobs"
                    className="inline-flex items-center px-6 py-3 bg-white text-blue-600 
                             rounded-lg font-semibold hover:bg-blue-50 transition-colors duration-200"
                  >
                    {t("careers.ctaButton")}
                    <ArrowRight className="ml-2 w-5 h-5" />
                  </a>
                </div>
              </div>

              {/* Stats Section */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
                {[
                  {
                    icon: Users,
                    stat: t("careers.stats.professionals.number"),
                    label: t("careers.stats.professionals.title"),
                    description: t("careers.stats.professionals.description")
                  },
                  {
                    icon: Briefcase,
                    stat: t("careers.stats.projects.number"),
                    label: t("careers.stats.projects.title"),
                    description: t("careers.stats.projects.description")
                  },
                  {
                    icon: Globe,
                    stat: t("careers.stats.countries.number"),
                    label: t("careers.stats.countries.title"),
                    description: t("careers.stats.countries.description")
                  }
                ].map((item, index) => (
                  <div
                    key={index}
                    className="text-center p-6 bg-white bg-opacity-10 rounded-xl"
                  >
                    <div className="flex justify-center mb-4">
                      <item.icon className="w-8 h-8 text-blue-200" />
                    </div>
                    <div className="text-3xl font-bold text-white mb-1">
                      {item.stat}
                    </div>
                    <div className="text-lg font-semibold text-blue-200 mb-2">
                      {item.label}
                    </div>
                    <div className="text-blue-100">
                      {item.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Additional Info Section */}
        <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-50 dark:bg-gray-800 p-8 rounded-xl">
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              {t("careers.selectionProcess.title")}
            </h3>
            <div className="space-y-4">
              {[0, 1, 2, 3].map((index) => (
                <div key={index} className="flex items-start">
                  <div className="flex-shrink-0 w-8 h-8 bg-blue-600 text-white rounded-full 
                                flex items-center justify-center font-bold">
                    {t(`careers.selectionProcess.steps.${index}.step`)}
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-semibold text-gray-900 dark:text-white">
                      {t(`careers.selectionProcess.steps.${index}.title`)}
                    </h4>
                    <p className="text-gray-600 dark:text-gray-300">
                      {t(`careers.selectionProcess.steps.${index}.description`)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gray-50 dark:bg-gray-800 p-8 rounded-xl">
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              {t("careers.faq.title")}
            </h3>
            <div className="space-y-6">
              {[0, 1, 2].map((index) => (
                <div key={index}>
                  <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                    {t(`careers.faq.questions.${index}.q`)}
                  </h4>
                  <p className="text-gray-600 dark:text-gray-300">
                    {t(`careers.faq.questions.${index}.a`)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
