import React from "react";

import ReactNative from "../../assets/images/logos/reactnative.webp";
import MERNLogo from "../../assets/images/logos/MERNLogo.webp";
import JavascriptLogo from "../../assets/images/logos/JavascriptLogo.webp";
import NodejsLogo from "../../assets/images/logos/NodeJSLogo.webp";
import MYSQLLogo from "../../assets/images/logos/MYSQLLogo.webp";
import MongoDBLogo from "../../assets/images/logos/mongodb.webp";
import WordpressLogo from "../../assets/images/logos/WordpressLogo.webp";
import WooCommerceLogo from "../../assets/images/logos/woocommerce.webp";
import FigmaLogo from "../../assets/images/logos/FigmaLogo.webp";
import GCPLogo from "../../assets/images/logos/gcp.webp";
import SQLLogo from "../../assets/images/logos/sql.webp";
import UbuntuLogo from "../../assets/images/logos/ubuntu.webp";
import FlutterLogo from "../../assets/images/logos/flutter.webp";
import NextjsLogo from "../../assets/images/logos/nextjs.webp";
import WebminLogo from "../../assets/images/logos/webmin.webp";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Tech() {
  const { t } = useTranslation();

  const technologies = [
    { src: ReactNative, alt: "React Native", height: "12" },
    { src: MERNLogo, alt: "MERN Stack", height: "12" },
    { src: JavascriptLogo, alt: "JavaScript", height: "12" },
    { src: NodejsLogo, alt: "Node.js", height: "12" },
    { src: NextjsLogo, alt: "Next.js", height: "12" },
    { src: MYSQLLogo, alt: "MySQL", height: "12" },
    { src: SQLLogo, alt: "SQL", height: "12" },
    { src: MongoDBLogo, alt: "MongoDB", height: "12" },
    { src: WordpressLogo, alt: "WordPress", height: "12" },
    { src: WooCommerceLogo, alt: "WooCommerce", height: "12" },
    { src: FlutterLogo, alt: "Flutter", height: "12" },
    { src: FigmaLogo, alt: "Figma", height: "12" },
    { src: GCPLogo, alt: "Google Cloud Platform", height: "12" },
    { src: UbuntuLogo, alt: "Ubuntu", height: "12" },
    { src: WebminLogo, alt: "Webmin", height: "12" }
  ];

  return (
    <div className="max-w-auto px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto bg-gradient-to-b from-slate-900 to-black">
      <div className="sm:w-1/2 xl:w-1/3 mx-auto text-center mb-6">
        <h2 className="text-2xl font-bold md:text-3xl md:leading-tight text-gray-200">
          {t("TechTitle.text")}
        </h2>
      </div>

      <div className="mx-auto mt-10 grid max-w-lg grid-cols-3 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-4 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
        {technologies.map((tech, index) => (
          <a key={index} className="flex-shrink-0 transition hover:-translate-y-1">
            <LazyLoadImage
              className={`col-span-2 max-h-${tech.height} w-full object-contain sm:col-start-2 lg:col-span-1`}
              src={tech.src}
              effect="blur"
              alt={tech.alt}
              width={165}
              height={50}
            />
          </a>
        ))}
      </div>
    </div>
  );
}

