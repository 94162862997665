import React from "react";
import { useTranslation } from "react-i18next";
import { AlertCircle, DollarSign, Clock, FileCheck } from "lucide-react";
import Image from "../../assets/3d/parallax-1.webp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function CtaImage() {
  const { t } = useTranslation();
  return (
    <section className="py-12">
      <div className="container px-4 mx-auto">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          {/* Imagen */}
          <div className="lg:w-1/2">
            <LazyLoadImage
              className="w-full h-auto rounded-2xl"
              src={Image}
              effect="blur"
              alt="Oferta especial WeCode"
            />
          </div>

          {/* Contenido */}
          <div className="lg:w-1/2 space-y-8">
            <div className="inline-flex items-center px-4 py-2 rounded-full bg-blue-100 dark:bg-blue-900">
              <AlertCircle className="w-5 h-5 text-blue-600 dark:text-blue-300 mr-2" />
              <span className="text-blue-600 dark:text-blue-300 font-semibold">
                {t("promo.ctaTitle")}
              </span>
            </div>

            <div className="space-y-6">
              {/* Costos adicionales */}
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <DollarSign className="w-6 h-6 text-blue-500" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-200 mb-2">
                    {t("promo.additionalCosts.title")}
                  </h3>
                  <p className="text-gray-300">
                    {t("promo.additionalCosts.description")}
                  </p>
                </div>
              </div>

              {/* Período de compromiso */}
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <Clock className="w-6 h-6 text-blue-500" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-200 mb-2">
                    {t("promo.commitmentPeriod.title")}
                  </h3>
                  <p className="text-gray-300">
                    {t("promo.commitmentPeriod.description")}
                  </p>
                </div>
              </div>

              {/* Propiedad del código */}
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <FileCheck className="w-6 h-6 text-blue-500" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-200 mb-2">
                    {t("promo.codeOwnership.title")}
                  </h3>
                  <p className="text-gray-300">
                    {t("promo.codeOwnership.description")}
                  </p>
                </div>
              </div>
            </div>

            {/* CTA Button */}
            <div className="pt-6">
              <a
                href="https://api.whatsapp.com/send?phone=50670602186&text=Hola,%20me%20interesa%20la%20oferta%20del%20sitio%20web%20por%20$50%20mensuales.%20¿Me%20pueden%20dar%20más%20información?"
                className="inline-flex items-center px-6 py-3 text-lg font-semibold rounded-lg bg-[#00acee] text-white hover:bg-blue-700 transition-colors duration-300"
              >
                {t("promo.ctaButton")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
