import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import Animation from "../../../assets/hosting/subhero.json";
import {
  Globe2,
  Shield,
  Clock,
  Server,
  Cloud,
  Network
} from "lucide-react";

export default function Details() {
  const features = [
    {
      icon: Server,
      title: "Recursos Dedicados",
      description: "Hardware exclusivo y recursos garantizados para máximo rendimiento"
    },
    {
      icon: Shield,
      title: "Seguridad Avanzada",
      description: "Protección DDoS, firewalls dedicados y SSL gratuito incluido"
    },
    {
      icon: Clock,
      title: "Soporte 24/7",
      description: "Equipo técnico especializado disponible en todo momento"
    },
    {
      icon: Network,
      title: "99.9% Uptime",
      description: "Infraestructura redundante para máxima disponibilidad"
    },
    {
      icon: Cloud,
      title: "Backups Automáticos",
      description: "Copias de seguridad diarias con retención de 7 días"
    },
    {
      icon: Globe2,
      title: "Red Global",
      description: "11 datacenters en 8 regiones para optimizar la latencia"
    }
  ];

  return (
    <section className="relative bg-gradient-to-b from-gray-50 to-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-20 items-center">
          {/* Columna izquierda - Animación */}
          <div className="relative">
            <div className="absolute -inset-4 bg-gradient-to-r from-blue-500 to-purple-600 rounded-3xl opacity-10 blur-xl" />
            <div className="relative">
              <Player
                autoplay
                loop
                speed={1}
                src={Animation}
                style={{ height: "auto", width: "100%" }}
              />
            </div>
          </div>

          {/* Columna derecha - Características */}
          <div>
            <div className="mb-12">
              <h2 className="text-4xl font-bold text-gray-900 mb-6">
                ¿Por qué elegirnos?
              </h2>
              <p className="text-xl text-gray-600">
                Infraestructura de clase mundial y características premium en todos nuestros planes
              </p>
            </div>

            <div className="space-y-8">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className="flex items-start gap-4 group"
                >
                  <div className="flex-shrink-0">
                    <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-500 to-blue-600 p-0.5">
                      <div className="w-full h-full bg-white rounded-[10px] flex items-center justify-center group-hover:bg-transparent transition-colors duration-300">
                        <feature.icon className="w-6 h-6 text-blue-500 group-hover:text-white transition-colors duration-300" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 mb-1 group-hover:text-blue-600 transition-colors duration-300">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
