import { useState as _useState$ } from "react";
import { renderReactScope as _renderReactScope$ } from "million/react";
import { block as _block$ } from "million/react";
//search.tsx

import React, { useState } from "react";
import { Search, MapPin, Briefcase, X } from "lucide-react";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
const M$2 = ({
  handleFilter
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");
  const {
    t
  } = useTranslation();
  const handleSearch = () => {
    handleFilter(selectedLocation, selectedJobType, searchInput);
  };
  const clearFilters = () => {
    setSearchInput("");
    setSelectedLocation("");
    setSelectedJobType("");
    handleFilter("", "", "");
  };
  const jobTypes = [{
    value: "",
    label: t("careers.jobTypes.all")
  }, {
    value: "Full Time",
    label: t("careers.jobTypes.fullTime")
  }, {
    value: "Part Time",
    label: t("careers.jobTypes.partTime")
  }, {
    value: "Temporary",
    label: t("careers.jobTypes.temporary")
  }, {
    value: "Commission",
    label: t("careers.jobTypes.commission")
  }, {
    value: "Per Hour",
    label: t("careers.jobTypes.perHour")
  }];
  const locations = [{
    value: "",
    label: t("careers.locations.all")
  }, {
    value: "Remote",
    label: t("careers.locations.remote")
  }, {
    value: "Heredia",
    label: t("careers.locations.heredia")
  }, {
    value: "San Jose",
    label: t("careers.locations.sanJose")
  }];
  const _portal = _useState$(() => ({
      $: new Array(6)
    }))[0],
    _ = e => {
      setSearchInput(e.target.value);
      handleFilter(selectedLocation, selectedJobType, e.target.value);
    },
    _2 = t("careers.searchPlaceholder"),
    _3 = _renderReactScope$(<Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />, false, _portal.$, 0, false),
    _4 = e => {
      setSelectedLocation(e.target.value);
      handleFilter(e.target.value, selectedJobType, searchInput);
    },
    _5 = _renderReactScope$(<select value={selectedLocation} onChange={_4} className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 
                             rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white 
                             focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none">
                    {locations.map(location => <option key={location.value} value={location.value}>
                        {location.label}
                      </option>)}
                  </select>, false, _portal.$, 1, false),
    _6 = _renderReactScope$(<MapPin className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />, false, _portal.$, 2, false),
    _7 = e => {
      setSelectedJobType(e.target.value);
      handleFilter(selectedLocation, e.target.value, searchInput);
    },
    _8 = _renderReactScope$(<select value={selectedJobType} onChange={_7} className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 
                             rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white 
                             focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none">
                    {jobTypes.map(type => <option key={type.value} value={type.value}>
                        {type.label}
                      </option>)}
                  </select>, false, _portal.$, 3, false),
    _9 = _renderReactScope$(<Briefcase className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />, false, _portal.$, 4, false),
    _10 = _renderReactScope$((searchInput || selectedLocation || selectedJobType) && <button onClick={clearFilters} className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg 
                           hover:bg-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-gray-500 focus:ring-offset-2 dark:bg-gray-600 
                           dark:text-gray-200 dark:hover:bg-gray-500">
                  <X className="w-5 h-5" />
                </button>, false, _portal.$, 5, false);
  return <><P$ searchInput={searchInput} _={_} _2={_2} _3={_3} selectedLocation={selectedLocation} _4={_4} _5={_5} _6={_6} selectedJobType={selectedJobType} _7={_7} _8={_8} _9={_9} _10={_10} />{_portal.$.map(p => p.portal)}</>;
};
const P$ = /*million:transform*/_block$(({
  searchInput,
  _,
  _2,
  _3,
  selectedLocation,
  _4,
  _5,
  _6,
  selectedJobType,
  _7,
  _8,
  _9,
  _10
}) => {
  return <div className="py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
          <div className="space-y-4">
            {void 0}
            <div className="flex flex-wrap gap-4">
              <div className="flex-1 min-w-[200px]">
                <div className="relative">
                  <input type="text" value={searchInput} onChange={_} placeholder={_2} className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 
                             rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white 
                             focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
                  {_3}
                </div>
              </div>

              {void 0}
              <div className="w-full sm:w-auto">
                <div className="relative">
                  {_5}
                  {_6}
                </div>
              </div>

              {void 0}
              <div className="w-full sm:w-auto">
                <div className="relative">
                  {_8}
                  {_9}
                </div>
              </div>

              {void 0}
              {_10}
            </div>
          </div>
        </div>
      </div>
    </div>;
}, {
  svg: false,
  shouldUpdate: (a, b) => a?.searchInput !== b?.searchInput || a?._ !== b?._ || a?._2 !== b?._2 || a?._3 !== b?._3 || a?.selectedLocation !== b?.selectedLocation || a?._4 !== b?._4 || a?._5 !== b?._5 || a?._6 !== b?._6 || a?.selectedJobType !== b?.selectedJobType || a?._7 !== b?._7 || a?._8 !== b?._8 || a?._9 !== b?._9 || a?._10 !== b?._10
});
const SearchBar = M$2;
export default SearchBar;