import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CheckCircle2, Server, ArrowRight } from "lucide-react";

export default function PaymentSuccess() {
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate();
  const location = useLocation();
  const orderDetails = location.state?.orderDetails;

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate('/hosting/panel');
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-2xl shadow-lg">
        <div className="text-center">
          <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
            <CheckCircle2 className="h-8 w-8 text-green-600" />
          </div>
          <h2 className="mt-6 text-3xl font-bold text-gray-900">
            ¡Pago completado!
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Tu servidor estará listo en unos minutos
          </p>
        </div>

        {orderDetails && (
          <div className="mt-8 bg-gray-50 rounded-xl p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Detalles del pedido
            </h3>
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Plan</span>
                <span className="font-medium">{orderDetails.plan.Title}</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Ubicación</span>
                <span className="font-medium">{orderDetails.location}</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Sistema Operativo</span>
                <span className="font-medium">{orderDetails.os}</span>
              </div>
              <div className="border-t border-gray-200 pt-3">
                <div className="flex items-center justify-between">
                  <span className="text-gray-900 font-medium">Total</span>
                  <span className="font-bold">${orderDetails.total}/{orderDetails.billingCycle}</span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mt-8">
          <div className="relative">
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">
                Redirigiendo al panel en {countdown} segundos
              </span>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-center">
          <button
            onClick={() => navigate('/hosting/panel')}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-800"
          >
            Ir al panel ahora
            <ArrowRight className="ml-2 h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
} 