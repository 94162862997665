import React, { useState, useEffect } from "react";
import IconBlack from "../../assets/images/Logo-v1.png";
import IconWhite from "../../assets/images/Logo-v1-full-white.png";
import { useTranslation } from "react-i18next";
import useTheme from "../useTheme.js"; // Asegúrate de ajustar la ruta según tu estructura de archivos
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Footer() {
  const { t } = useTranslation();
  const [theme] = useTheme(); // Usa el hook useTheme para obtener el tema actual

  const [darkToggle, setDarkToggle] = useState(
    () => localStorage.getItem("darkMode") === "true"
  );

  useEffect(() => {
    if (darkToggle) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkToggle]);

  return (
    <footer className="bg-white dark:bg-gray-900">
      <div className="container p-6 mx-auto">
        <div className="lg:flex">
          <div className="w-full -mx-6 lg:w-2/5">
            <div className="px-6">
              <a href="#">
                {theme === "dark" ? (
                  <LazyLoadImage
                    className="w-41 h-10 mt-3"
                    src={IconWhite}
                    effect="blur"
                    alt="Dark Icon"
                  />
                ) : (
                  <LazyLoadImage
                    className="w-41 h-10 mt-3"
                    src={IconBlack}
                    effect="blur"
                    alt="Icon WeCode CR"
                  />
                )}
              </a>
              <p className="max-w-sm mt-2 text-gray-500 dark:text-gray-400">
                {t("FooterText.text")}
              </p>

              <div className="flex mt-4 -mx-2">
                <a
                  href="https://www.instagram.com/wecodecr/"
                  className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                  aria-label="Instagram"
                >
                  <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                    <circle cx="15" cy="15" r="4" />
                    <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                  </svg>
                </a>

                <a
                  href="https://twitter.com/WeCodeCR"
                  className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                  aria-label="Twitter"
                >
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 512 512"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="currentColor"
                      stroke="none"
                    >
                      <path
                        d="M75 5093 c10 -16 448 -653 973 -1418 525 -765 955 -1395 955 -1400 0
                -6 -431 -512 -959 -1126 -527 -613 -965 -1123 -973 -1132 -13 -16 1 -17 206
                -17 l220 0 849 987 c467 542 854 988 859 989 6 2 316 -442 689 -986 l679 -990
                744 0 c705 0 743 1 734 18 -5 9 -460 672 -1010 1472 -550 800 -1001 1457
                -1001 1460 0 4 418 492 929 1085 l928 1080 -215 3 c-177 2 -218 0 -231 -12 -9
                -8 -374 -431 -811 -939 -437 -509 -798 -923 -802 -921 -4 3 -296 425 -649 939
                l-642 935 -745 0 -744 0 17 -27z m2815 -2529 c855 -1224 1557 -2229 1558
                -2235 3 -6 -118 -8 -334 -7 l-338 3 -1558 2228 c-857 1226 -1558 2231 -1558
                2233 0 2 152 4 338 4 l337 -1 1555 -2225z"
                      />
                    </g>
                  </svg>
                </a>

                <a
                  href="https://www.facebook.com/wecodecostarica/"
                  className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                  aria-label="Facebook"
                >
                  <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                    <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                  </svg>
                </a>

                <a
                  href="https://www.linkedin.com/company/wecode-costa-rica/"
                  className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                  aria-label="Github"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 fill-current"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1 2.838A1.838 1.838 0 0 1 2.838 1H21.16A1.837 1.837 0 0 1 23 2.838V21.16A1.838 1.838 0 0 1 21.161 23H2.838A1.838 1.838 0 0 1 1 21.161V2.838zm8.708 6.55h2.979v1.496c.43-.86 1.53-1.634 3.183-1.634 3.169 0 3.92 1.713 3.92 4.856v5.822h-3.207v-5.106c0-1.79-.43-2.8-1.522-2.8-1.515 0-2.145 1.089-2.145 2.8v5.106H9.708V9.388zm-5.5 10.403h3.208V9.25H4.208v10.54zM7.875 5.812a2.063 2.063 0 1 1-4.125 0 2.063 2.063 0 0 1 4.125 0z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div className="mt-6 lg:mt-0 lg:flex-1">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              <div>
                <h3 className="text-gray-700 uppercase font-semibold dark:text-white">
                  {t("FooterText.about")}
                </h3>
                <a
                  href="/careers"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  {t("FooterText.careers")}
                </a>
                <a
                  href="/careers"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  {t("FooterText.internship")}
                </a>
                <a
                  href="/team"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  {t("FooterText.team")}
                </a>
              </div>

              {/* <div>
                <h3 className="text-gray-700 uppercase font-semibold dark:text-white">
                  {t("FooterText.blog")}
                </h3>
                <a
                  href="https://nexus.wecodecr.com/category/ia/"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  {t("FooterText.ia")}
                </a>
                <a
                  href="https://nexus.wecodecr.com/category/gaming/"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  {t("FooterText.gaming")}
                </a>
                <a
                  href="https://nexus.wecodecr.com/category/tech/"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  {t("FooterText.technology")}
                </a>
              </div> */}

               <div>
                <h3 className="text-gray-700 uppercase font-semibold dark:text-white">
                  Server Status
                </h3>
                <a
                  href="https://status.wecodecr.com/"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  Primary Zone
                </a>
                
              </div> 

              <div>
                <h3 className="text-gray-700 uppercase font-semibold dark:text-white">
                  {t("FooterText.contact")}
                </h3>
                <a
                  href="mailto:contact@wecodecr.com"
                  className="block mt-2 text-sm text-[#00acee] dark:text-[#00acee] hover:underline"
                >
                  contact@wecodecr.com
                </a>
                
                <a
                  href="https://api.whatsapp.com/send?phone=50670602186&text=Hola,%20quisiera%20solicitar%20informaci%C3%B3n%20sobre%20los%20servicios%20de%20WeCode."
                  className="block mt-2 text-sm text-[#25D366] dark:text-[#25D366] hover:underline"
                >
                  WhatsApp
                </a>
                <span className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">
                  Republic Workspace Centro Comercial Santa Verde,La Aurora, Heredia
                </span>
                <span className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">
                  Venture X City Mall, Alajuela
                </span>
              </div>

              <div>
                <h3 className="text-gray-700 uppercase font-semibold dark:text-white">
                  {t("FooterText.policies")}
                </h3>
                <a
                  href="/cancellation"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  {t("FooterText.cancellation")}
                </a>
                <a
                  href="/refund"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  {t("FooterText.refund")}
                </a>
                <a
                  href="/privacy"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  {t("FooterText.privacy")}
                </a>
                <a
                  href="/security"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  {t("FooterText.security")}
                </a>
              </div>
            </div>
          </div>
        </div>

        <hr className="h-px my-6 bg-gray-200 border-none dark:bg-gray-700" />

        <div>
          <p className="text-center text-gray-500 dark:text-gray-400">
            {t("FooterText.copyright")}
          </p>
        </div>
      </div>
    </footer>
  );
}

