import React from "react";
import { useTranslation } from "react-i18next";
import { Code, Server, Shield, Clock } from "lucide-react";

export default function Steps() {
  const { t } = useTranslation();
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-200 sm:text-4xl md:mx-auto">
          {t("promo.stepTitle")}
        </h2>
        <p className="text-base text-gray-300 md:text-lg">
          {t("promo.subtitle4")}
        </p>
      </div>
      <div className="grid gap-8 lg:grid-cols-2">
        <div className="flex flex-col p-8 rounded-lg bg-gray-800/50 backdrop-blur-sm transition-all duration-300 hover:bg-gray-800">
          <div className="mb-6">
            <div className="flex items-center gap-4 mb-4">
              <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-blue-600">
                <Code className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-xl font-bold text-gray-200">
                {t("promo.step1Title")}
              </h3>
            </div>
            <p className="text-gray-300">
              {t("promo.step1Description")}
            </p>
          </div>
          <div className="space-y-3">
            {t("promo.features.website", { returnObjects: true }).map((feature, index) => (
              <div key={index} className="flex items-center text-gray-300">
                <div className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></div>
                {feature}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col p-8 rounded-lg bg-gray-800/50 backdrop-blur-sm transition-all duration-300 hover:bg-gray-800">
          <div className="mb-6">
            <div className="flex items-center gap-4 mb-4">
              <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-blue-600">
                <Server className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-xl font-bold text-gray-200">
                {t("promo.step2Title")}
              </h3>
            </div>
            <p className="text-gray-300">
              {t("promo.step2Description")}
            </p>
          </div>
          <div className="space-y-3">
            {t("promo.features.hosting", { returnObjects: true }).map((feature, index) => (
              <div key={index} className="flex items-center text-gray-300">
                <div className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></div>
                {feature}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col p-8 rounded-lg bg-gray-800/50 backdrop-blur-sm transition-all duration-300 hover:bg-gray-800">
          <div className="mb-6">
            <div className="flex items-center gap-4 mb-4">
              <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-blue-600">
                <Shield className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-xl font-bold text-gray-200">
                {t("promo.step3Title")}
              </h3>
            </div>
            <p className="text-gray-300">
              {t("promo.step3Description")}
            </p>
          </div>
          <div className="space-y-3">
            {t("promo.features.maintenance", { returnObjects: true }).map((feature, index) => (
              <div key={index} className="flex items-center text-gray-300">
                <div className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></div>
                {feature}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col p-8 rounded-lg bg-gray-800/50 backdrop-blur-sm transition-all duration-300 hover:bg-gray-800">
          <div className="mb-6">
            <div className="flex items-center gap-4 mb-4">
              <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-blue-600">
                <Clock className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-xl font-bold text-gray-200">
                {t("promo.step4Title")}
              </h3>
            </div>
            <p className="text-gray-300">
              {t("promo.step4Description")}
            </p>
          </div>
          <div className="space-y-3">
            {t("promo.features.commitment", { returnObjects: true }).map((feature, index) => (
              <div key={index} className="flex items-center text-gray-300">
                <div className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></div>
                {feature}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
