import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { 
  Server, 
  Globe, 
  Shield, 
  CreditCard,
  ArrowLeft,
  Check,
  Lock,
  ArrowRight
} from "lucide-react";

export default function Checkout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { plan, billingCycle } = location.state || {};
  
  const [step, setStep] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedOS, setSelectedOS] = useState("");
  const [total, setTotal] = useState(0);

  const [selectedAddons, setSelectedAddons] = useState({
    singleSelect: {
      BACKUP: null,
      CONTROL_PANEL: null,
      SSL: null,
      SERVER_MANAGEMENT: null
    },
    multiSelect: {
      NETWORKING: [],
      SECURITY: [],
      MONITORING: []
    }
  });

  const groupedAddons = useMemo(() => {
    if (!plan?.addons) return {};
    
    return plan.addons.reduce((groups, addon) => {
      const category = addon.Category.toUpperCase();
      if (!groups[category]) groups[category] = [];
      groups[category].push(addon);
      return groups;
    }, {});
  }, [plan?.addons]);

  useEffect(() => {
    if (!plan) {
      navigate('/hosting/vps');
      return;
    }
  }, [plan, navigate]);

  const calculateTotal = () => {
    if (!plan) return 0;

    let total = billingCycle === 'annual' 
      ? plan.BasePrice * 12 * 0.8 
      : plan.BasePrice;

    if (selectedLocation) {
      total += plan.locations.find(loc => loc.Region === selectedLocation)?.Price || 0;
    }
    
    if (selectedOS) {
      total += plan.operatingSystems.find(
        os => `${os.Name} ${os.Version}` === selectedOS
      )?.Price || 0;
    }

    Object.values(selectedAddons.singleSelect).forEach(addon => {
      if (addon) {
        total += addon.Price;
      }
    });

    Object.values(selectedAddons.multiSelect).forEach(addons => {
      addons.forEach(addon => {
        total += addon.Price;
      });
    });

    return total;
  };

  useEffect(() => {
    setTotal(calculateTotal());
  }, [plan, selectedLocation, selectedOS, selectedAddons, billingCycle]);

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  const handleOSSelect = (os) => {
    setSelectedOS(os);
  };

  const handleAddonSelect = (addon, category) => {
    if (!addon || !category) return;
    
    category = category.toUpperCase();
    const isSingleSelect = ['BACKUP', 'CONTROL_PANEL', 'SSL', 'SERVER_MANAGEMENT'].includes(category);

    if (isSingleSelect) {
      setSelectedAddons(prev => ({
        ...prev,
        singleSelect: {
          ...prev.singleSelect,
          [category]: prev.singleSelect[category]?.ID === addon.ID ? null : addon
        }
      }));
    } else {
      setSelectedAddons(prev => {
        const currentList = prev.multiSelect[category] || [];
        const isSelected = currentList.some(item => item.ID === addon.ID);

        return {
          ...prev,
          multiSelect: {
            ...prev.multiSelect,
            [category]: isSelected
              ? currentList.filter(item => item.ID !== addon.ID)
              : [...currentList, addon]
          }
        };
      });
    }
  };

  const handleNextStep = () => {
    if (step < 4) setStep(step + 1);
  };

  const handlePrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      navigate(-1);
    }
  };

  const handlePayment = async () => {
    try {
      const orderDetails = {
        plan,
        location: selectedLocation,
        os: selectedOS,
        addons: selectedAddons,
        total,
        billingCycle
      };

      navigate('/hosting/payment/success', { state: { orderDetails } });
    } catch (error) {
      navigate('/hosting/payment/error', { state: { error: error.message } });
    }
  };

  if (!plan) return null;

  const renderStepIndicator = () => (
    <div className="fixed top-0 left-0 right-0 bg-white border-b z-50">
      <div className="max-w-7xl mx-auto px-4 py-4">
        <div className="flex items-center justify-between relative">
          {['Ubicación', 'Sistema', 'Addons', 'Pago'].map((label, idx) => (
            <div key={idx} className="flex flex-col items-center relative">
              <div
                className={`
                  flex items-center justify-center w-10 h-10 rounded-full mb-2
                  ${step > idx + 1 ? 'bg-green-500' : step === idx + 1 ? 'bg-black' : 'bg-gray-100'}
                  ${step >= idx + 1 ? 'text-white' : 'text-gray-400'}
                `}
              >
                {step > idx + 1 ? <Check className="w-6 h-6" /> : idx + 1}
              </div>
              <span
                className={`text-sm ${step === idx + 1 ? 'text-black font-medium' : 'text-gray-500'}`}
              >
                {label}
              </span>
              {idx < 3 && (
                <div
                  className={`
                    absolute top-4 left-[50%] w-[150%] h-[2px] mt-5
                    ${step > idx + 1 ? 'bg-green-500' : 'bg-gray-100'}
                  `}
                  style={{ zIndex: -1 }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderFloatingTotal = () => (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg z-50">
      <div className="max-w-7xl mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <div>
            <span className="text-gray-500">Total estimado:</span>
            <div className="text-2xl font-bold">
              ${total.toFixed(2)}/{billingCycle === 'annual' ? 'año' : 'mes'}
            </div>
          </div>
          {step < 4 ? (
            <button
              onClick={handleNextStep}
              disabled={
                (step === 1 && !selectedLocation) ||
                (step === 2 && !selectedOS)
              }
              className="bg-black text-white px-8 py-4 rounded-full font-medium disabled:bg-gray-300 
                hover:bg-gray-800 transition-all duration-300 min-w-[200px]"
            >
              Continuar
            </button>
          ) : (
            <button
              onClick={handlePayment}
              className="w-full bg-black text-white py-4 rounded-xl font-medium 
                hover:bg-gray-800 transition-all duration-300"
            >
              Confirmar y pagar
            </button>
          )}
        </div>
      </div>
    </div>
  );

  const renderLocationStep = () => (
    <div className="space-y-8">
      <div className="bg-gray-50 p-6 rounded-2xl mb-8">
        <h2 className="text-2xl font-semibold mb-2">Tu plan seleccionado</h2>
        <div className="flex items-center gap-4">
          <div className="bg-black rounded-xl p-3">
            <Server className="w-6 h-6 text-white" />
          </div>
          <div>
            <h3 className="font-medium">{plan.Title}</h3>
            <p className="text-gray-500">{plan.Description}</p>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        <h2 className="text-2xl font-semibold">¿Dónde quieres tu servidor?</h2>
        <p className="text-gray-500">
          Elige la ubicación más cercana a tus usuarios para mejor rendimiento
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {plan.locations.map((location) => (
            <button
              key={location.LocationID}
              onClick={() => handleLocationSelect(location.Region)}
              className={`
                group relative overflow-hidden p-6 rounded-2xl border-2 transition-all duration-300
                ${
                  selectedLocation === location.Region 
                    ? 'border-black bg-black text-white' 
                    : 'border-gray-200 hover:border-black'
                }
              `}
            >
              <div className="flex items-center gap-4">
                <div
                  className={`
                    p-3 rounded-xl transition-colors duration-300
                    ${
                      selectedLocation === location.Region
                        ? 'bg-white/20'
                        : 'bg-gray-100 group-hover:bg-black group-hover:text-white'
                    }
                  `}
                >
                  <Globe className="w-6 h-6" />
                </div>
                <div className="text-left">
                  <p className="font-medium">{location.Region}</p>
                  <p
                    className={`text-sm ${
                      selectedLocation === location.Region
                        ? 'text-white/70'
                        : 'text-gray-500'
                    }`}
                  >
                    Latencia optimizada
                  </p>
                </div>
              </div>
              
              {location.Price > 0 && (
                <div
                  className={`
                    absolute top-4 right-4 px-3 py-1 rounded-full text-sm
                    ${
                      selectedLocation === location.Region
                        ? 'bg-white/20'
                        : 'bg-gray-100 group-hover:bg-black group-hover:text-white'
                    }
                  `}
                >
                  +${location.Price}/mes
                </div>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );

  const renderOSStep = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold">Elige tu sistema operativo</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {plan.operatingSystems.map((os) => {
          const osKey = `${os.Name} ${os.Version}`;
          return (
            <button
              key={os.OSID}
              onClick={() => handleOSSelect(osKey)}
              className={`
                flex items-center justify-between p-6 rounded-xl border-2 transition-all
                ${
                  selectedOS === osKey
                    ? 'border-black bg-black/5'
                    : 'border-gray-200 hover:border-gray-300'
                }
              `}
            >
              <div className="flex items-center gap-4">
                <Shield
                  className={`w-6 h-6 ${
                    selectedOS === osKey ? 'text-black' : 'text-gray-400'
                  }`}
                />
                <div className="text-left">
                  <p className="font-medium">{os.Name}</p>
                  <p className="text-sm text-gray-500">Versión {os.Version}</p>
                </div>
              </div>
              {os.Price > 0 && (
                <span className="text-sm font-medium">+${os.Price}/mes</span>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );

  const renderAddonsStep = () => {
    if (!plan?.addons) return null;

    return (
      <div className="space-y-8">
        <h2 className="text-2xl font-semibold">Personaliza tu servidor</h2>
        
        {Object.entries(selectedAddons.singleSelect).map(([category, selected]) => {
          const addons = groupedAddons[category] || [];
          if (!addons.length) return null;

          return (
            <div key={category} className="space-y-4">
              <h3 className="text-lg font-medium">
                {category.split('_').join(' ')}
                <span className="text-sm text-gray-500 ml-2">(Selecciona uno)</span>
              </h3>
              <select
                value={selected?.ID || ''}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!value) {
                    handleAddonSelect(null, category);
                    return;
                  }
                  const selectedAddon = addons.find(a => a.ID === Number(value));
                  if (selectedAddon) {
                    handleAddonSelect(selectedAddon, category);
                  }
                }}
                className="w-full p-4 border-2 border-gray-200 rounded-xl bg-white focus:outline-none focus:border-blue-500"
              >
                <option value="">Seleccionar opción</option>
                {addons.map(addon => (
                  <option key={addon.ID} value={addon.ID}>
                    {addon.Name} (+${addon.Price.toFixed(2)}/mes)
                  </option>
                ))}
              </select>
            </div>
          );
        })}

        {Object.entries(selectedAddons.multiSelect).map(([category, selected]) => {
          const addons = groupedAddons[category] || [];
          if (!addons.length) return null;

          return (
            <div key={category} className="space-y-4">
              <h3 className="text-lg font-medium">{category.split('_').join(' ')}</h3>
              <div className="space-y-2">
                {addons.map(addon => {
                  const isSelected = selected.some(a => a.ID === addon.ID);

                  return (
                    <div
                      key={addon.ID}
                      className={`p-4 border-2 rounded-lg cursor-pointer
                        ${isSelected ? 'border-blue-500 bg-blue-50' : 'border-gray-200'}
                        hover:border-gray-300`}
                      onClick={() => handleAddonSelect(addon, category)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-3">
                          <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleAddonSelect(addon, category);
                            }}
                            className="w-5 h-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          />
                          <div>
                            <p className="font-medium">{addon.Name}</p>
                            <p className="text-sm text-gray-500">{addon.Description}</p>
                          </div>
                        </div>
                        <span className="text-sm font-medium">
                          +${addon.Price.toFixed(2)}/mes
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderPaymentStep = () => (
    <div className="space-y-8">
      <div className="bg-gray-50 p-6 rounded-xl">
        <h2 className="text-2xl font-semibold mb-6">Resumen del pedido</h2>
        
        <div className="space-y-4">
          <div className="flex justify-between items-center pb-4 border-b">
            <div>
              <h3 className="font-medium">{plan.Title}</h3>
              <p className="text-sm text-gray-500">{plan.Description}</p>
            </div>
            <span className="font-medium">
              ${billingCycle === 'annual' 
                ? (plan.BasePrice * 12 * 0.8).toFixed(2) 
                : plan.BasePrice.toFixed(2)
              }
            </span>
          </div>

          {selectedLocation && (
            <div className="flex justify-between items-center py-2">
              <div>
                <h4 className="font-medium">Ubicación</h4>
                <p className="text-sm text-gray-500">{selectedLocation}</p>
              </div>
              <span className="font-medium">
                +${plan.locations
                  .find(loc => loc.Region === selectedLocation)
                  ?.Price.toFixed(2) || '0.00'}
              </span>
            </div>
          )}

          {selectedOS && (
            <div className="flex justify-between items-center py-2">
              <div>
                <h4 className="font-medium">Sistema Operativo</h4>
                <p className="text-sm text-gray-500">{selectedOS}</p>
              </div>
              <span className="font-medium">
                +${plan.operatingSystems
                  .find(os => `${os.Name} ${os.Version}` === selectedOS)
                  ?.Price.toFixed(2) || '0.00'}
              </span>
            </div>
          )}

          {Object.entries(selectedAddons).map(([category, addon]) => {
            if (!addon) return null;

            return (
              <div key={category} className="flex justify-between items-center py-2">
                <div>
                  <h4 className="font-medium">{category.split('_').join(' ')}</h4>
                  <p className="text-sm text-gray-500">{addon.Name}</p>
                </div>
                <span className="font-medium">+${addon.Price.toFixed(2)}</span>
              </div>
            );
          })}

          <div className="flex justify-between items-center pt-4 border-t border-gray-200">
            <div>
              <h4 className="text-lg font-semibold">Total</h4>
              <p className="text-sm text-gray-500">
                {billingCycle === 'annual' ? 'Facturación anual' : 'Facturación mensual'}
              </p>
            </div>
            <div className="text-right">
              <span className="text-2xl font-bold">${total.toFixed(2)}</span>
              <span className="block text-sm text-gray-500">
                /{billingCycle === 'annual' ? 'año' : 'mes'}
              </span>
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={handlePayment}
        className="w-full bg-black text-white py-4 px-6 rounded-xl font-medium 
          hover:bg-gray-800 transition-all duration-300 flex items-center justify-center gap-2"
      >
        <span>Confirmar y pagar</span>
        <ArrowRight className="w-5 h-5" />
      </button>
    </div>
  );

  return (
    <div className="min-h-screen bg-white pt-32 pb-32">
      {renderStepIndicator()}
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={handlePrevStep}
          className="flex items-center gap-2 text-gray-500 hover:text-black transition-colors duration-200 mb-8"
        >
          <ArrowLeft className="w-5 h-5" />
          <span className="font-medium">Volver</span>
        </button>

        <div className="mb-8">
          {step === 1 && renderLocationStep()}
          {step === 2 && renderOSStep()}
          {step === 3 && renderAddonsStep()}
          {step === 4 && renderPaymentStep()}
        </div>
      </div>

      {renderFloatingTotal()}
    </div>
  );
}
