import React from "react";
import Navbar from "../navbar";
import { Player } from "@lottiefiles/react-lottie-player";
import Animation from "../../../assets/hosting/hero.json";
import Datacenters from "./datacenters";
import Details from "./DetailsCard.js";
import Brands from "./brands";
import SSD from "./ssd";
import ContaboLogo from "../../../assets/hosting/Contabo.png";
import Footer from "../../home/footer.js";
import Pricing from "../vps/pricing";
import { 
  Server, 
  Shield, 
  Globe2, 
  Clock, 
  HeartPulse,
  Users,
  Cpu,
  Network
} from "lucide-react";
import Awards from "../../../assets/hosting/contabo-awards.webp";

export default function Hosting() {
  return (
    <div className="bg-white">
      {/* Hero Section con Navbar integrado */}
      <div className="relative overflow-hidden bg-gradient-to-t from-blue-900 to-black"> {/* Invertimos el gradiente */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-black/90 to-blue-900/90" /> {/* También invertimos este */}
          <div className="absolute inset-0" 
            style={{
              backgroundImage: 'radial-gradient(rgba(255,255,255,0.1) 1px, transparent 1px)',
              backgroundSize: '24px 24px'
            }}
          />
        </div>

        {/* Navbar dentro del hero */}
        <header className="relative z-50">
          <nav className="max-w-[85rem] w-full mx-auto px-4 md:px-6 lg:px-8 py-4">
            <Navbar />
          </nav>
        </header>

        <div className="relative">
          <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-24 lg:py-32">
            <div className="grid lg:grid-cols-2 gap-12 items-center">
              <div>
                <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-6">
                  Servidores de alto rendimiento
                </h1>
                <p className="text-xl text-gray-300 mb-8 max-w-2xl">
                  Garantizamos un tiempo de actividad del 99.9% para todos nuestros servicios, 
                  respaldados por una infraestructura de clase mundial y soporte técnico 24/7.
                </p>
                <div className="flex flex-wrap gap-4">
                  <a
                    href="#plans"
                    className="inline-flex items-center px-6 py-3 text-base font-medium rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200"
                  >
                    Ver planes
                    <Server className="ml-2 -mr-1 w-5 h-5" />
                  </a>
                  <a
                    href="#contact"
                    className="inline-flex items-center px-6 py-3 text-base font-medium rounded-lg bg-white/10 text-white hover:bg-white/20 transition-colors duration-200"
                  >
                    Contactar
                    <Users className="ml-2 -mr-1 w-5 h-5" />
                  </a>
                </div>

                {/* Stats */}
                <div className="mt-12 grid grid-cols-2 sm:grid-cols-4 gap-6">
                  <div className="text-center">
                    <div className="text-3xl font-bold text-white">99.9%</div>
                    <div className="text-sm text-gray-400">Uptime</div>
                  </div>
                  <div className="text-center">
                    <div className="text-3xl font-bold text-white">24/7</div>
                    <div className="text-sm text-gray-400">Soporte</div>
                  </div>
                  <div className="text-center">
                    <div className="text-3xl font-bold text-white">9</div>
                    <div className="text-sm text-gray-400">Datacenters</div>
                  </div>
                  <div className="text-center">
                    <div className="text-3xl font-bold text-white">1M+</div>
                    <div className="text-sm text-gray-400">Clientes</div>
                  </div>
                </div>
              </div>

              <div className="hidden lg:block">
                <Player
                  autoplay
                  loop
                  speed={1}
                  src={Animation}
                  style={{ height: "auto", width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ¿Por qué elegirnos? Section */}
      <div className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
              ¿Por qué elegirnos?
            </h2>
            <p className="mt-4 text-lg text-gray-600">
              Soluciones de hosting diseñadas para tu éxito
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: Shield,
                title: "Seguridad Garantizada",
                description: "Protección DDoS avanzada y firewalls dedicados incluidos en todos los planes"
              },
              {
                icon: Clock,
                title: "Soporte 24/7",
                description: "Equipo técnico especializado disponible en todo momento para ayudarte"
              },
              {
                icon: Cpu,
                title: "Hardware Premium",
                description: "Servidores de última generación con componentes de alta calidad"
              },
              {
                icon: Network,
                title: "Red Global",
                description: "9 ubicaciones estratégicas para optimizar la latencia y el rendimiento"
              }
            ].map((feature, index) => (
              <div key={index} className="relative p-8 bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
                <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <div className="w-12 h-12 bg-gradient-to-r from-blue-600 to-blue-800 rounded-full flex items-center justify-center">
                    <feature.icon className="w-6 h-6 text-white" />
                  </div>
                </div>
                <div className="pt-8 text-center">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600">
                    {feature.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    

      {/* Details Cards */}
      <Details />

      {/* Brands Section */}
      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-center text-gray-900 mb-8">
            Tecnologías soportadas
          </h2>
          <Brands />
        </div>
      </div>

      {/* Pricing Section */}
      <div id="plans">
        <Pricing />
      </div>

      {/* Datacenters Section */}
      <div className="py-24 bg-gray-50">
        <div className=" mx-auto">
          
          <Datacenters />
        </div>
      </div>

      {/* SSD Section */}
      <SSD />

      {/* Partnership Section */}
      <div className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <img className="h-12 w-auto mx-auto mb-6" src={ContaboLogo} alt="Contabo" />
            <h3 className="text-xl text-gray-900 mb-6">
              Este servicio es brindado en colaboración entre Contabo y WeCode
            </h3>
            <p className="text-gray-600 mb-12">
              Contabo es uno de los principales proveedores de servidores en Europa
            </p>
            <img
              className="h-64 w-auto object-contain mx-auto"
              src={Awards}
              alt="Contabo Awards"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
