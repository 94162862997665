import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { XCircle, RefreshCw, ArrowLeft } from "lucide-react";

export default function PaymentError() {
  const [countdown, setCountdown] = useState(15);
  const navigate = useNavigate();
  const location = useLocation();
  const error = location.state?.error || "Ha ocurrido un error durante el proceso de pago";

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate('/hosting/checkout');
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-2xl shadow-lg">
        <div className="text-center">
          <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-100">
            <XCircle className="h-8 w-8 text-red-600" />
          </div>
          <h2 className="mt-6 text-3xl font-bold text-gray-900">
            Error en el pago
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {error}
          </p>
        </div>

        <div className="mt-8">
          <div className="grid grid-cols-2 gap-4">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              Volver
            </button>
            <button
              onClick={() => window.location.reload()}
              className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              <RefreshCw className="mr-2 h-4 w-4" />
              Reintentar
            </button>
          </div>
        </div>

        <div className="mt-8 text-center text-sm text-gray-500">
          Serás redirigido al checkout en {countdown} segundos
        </div>
      </div>
    </div>
  );
} 